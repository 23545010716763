import { useState, useEffect } from 'react';

function debounce(fn, delay) {
  let wait = false;
  return function () {
    if (wait) return;
    fn.apply(this, arguments);
    wait = true;
    setTimeout(() => (wait = false), delay);
  };
}

const useMediaQuery = ({ min = 0, max = 3000 }) => {
  const [match, setMatch] = useState(getMatchValue());

  function getMatchValue() {
    return window.innerWidth > min && window.innerWidth < max;
  }

  const getInnerWidth = debounce(() => setMatch(getMatchValue()), 100);

  useEffect(() => {
    window.addEventListener('resize', getInnerWidth);
    return () => window.removeEventListener('resize', getInnerWidth);
  }, []);

  return match;
};

export default useMediaQuery;
