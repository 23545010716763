import React, { useEffect, useState } from 'react';
import NavigationBar from '../../builder/Interface/NavigationBar/NavigationBar';
import TopBar from '../../builder/Interface/TopBar/TopBar';

import { INSTAGRAM_FEED_LAYOUT_UPDATE } from '../../../../store/app/widget-settings/actionTypes';
import useWidgetPreview from '../../../hooks/useWidgetPreview';
import { useWidgetSettings } from '../../../hooks/useWidgetSettings';
import { useDispatch } from 'react-redux';
import useProjects from '../../../hooks/useProjects';
import { useNavigate } from 'react-router-dom';
import { Container, Form, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

export default function Layouts() {
  const { isPreviewLoading } = useWidgetPreview();
  const { settings, setActiveWidgetSettings } = useWidgetSettings();
  const dispatch = useDispatch();
  const { activeProject, saveProjectSettings } = useProjects();
  const navigate = useNavigate();

  const handleClick = async () => {
    await saveProjectSettings(activeProject.id, settings);
    navigate('/home');
  };

  const handleChange = (typeParams, e) => {
    let checkboxAction = { [typeParams]: e.target.checked };
    let defaultAction = { [typeParams]: e.target.value };

    let action = e.target.type === 'checkbox' ? checkboxAction : defaultAction;
    dispatch({ type: INSTAGRAM_FEED_LAYOUT_UPDATE, payload: action });
  };

  return (
    <div className="bootstrap-app-styles builder-styles">
      <TopBar onClick={handleClick}>Widget Layout</TopBar>
      <Container>
        <Form className={'p-3'}>
          <ToggleButtonGroup name="options" className="w-100 mb-4">
            <ToggleButton
              variant={settings.params.type === 'gallery' ? 'primary' : 'outline-primary'}
              value="gallery"
              onChange={handleChange.bind(this, 'type')}
              checked={settings.params.type === 'gallery'}
              id="radio-gallery">
              Gallery
            </ToggleButton>
            <ToggleButton
              variant={settings.params.type === 'slider' ? 'primary' : 'outline-primary'}
              value="slider"
              onChange={handleChange.bind(this, 'type')}
              checked={settings.params.type === 'slider'}
              id="radio-slider">
              Slider
            </ToggleButton>
          </ToggleButtonGroup>

          {settings.params.type === 'gallery' && (
            <>
              <Form.Label>Columns</Form.Label>
              <Form.Range
                value={settings.params.columns}
                onChange={handleChange.bind(this, 'columns')}
                min="1"
                max="7"
                step="1"
              />
              <Form.Label>Rows</Form.Label>
              <Form.Range
                value={settings.params.rows}
                onChange={handleChange.bind(this, 'rows')}
                min="1"
                max="4"
                step="1"
              />
            </>
          )}
          <Form.Check
            onClick={handleChange.bind(this, 'captions')}
            type="switch"
            label="Captions"
            defaultChecked={settings.params.captions}
          />
          <Form.Check
            onClick={handleChange.bind(this, 'shadow')}
            type="switch"
            label="Shadow"
            defaultChecked={settings.params.shadow}
          />
          <Form.Check
            onClick={handleChange.bind(this, 'spacing')}
            type="switch"
            label="Spacing"
            defaultChecked={settings.params.spacing}
          />
          {settings.params.spacing === true && (
            <>
              <Form.Label>Gap</Form.Label>
              <Form.Range
                onChange={handleChange.bind(this, 'gap')}
                value={settings.params.gap}
                min="0"
                max="5"
                step="1"
              />
            </>
          )}
          <Form.Check
            onClick={handleChange.bind(this, 'rounded')}
            type="switch"
            label="Rounded"
            defaultChecked={settings.params.rounded}
          />
          {settings.params.type === 'slider' && (
            <>
              <Form.Check
                onClick={handleChange.bind(this, 'autoPlay')}
                type="switch"
                label="Autoplay"
                defaultChecked={settings.params.autoPlay}
              />
              {settings.params.autoPlay === true && (
                <>
                  <Form.Label>Delay</Form.Label>
                  <Form.Range
                    onChange={handleChange.bind(this, 'delay')}
                    value={settings.params.delay}
                    min="1000"
                    max="5000"
                    step="1000"
                  />
                </>
              )}

              <Form.Check
                onClick={handleChange.bind(this, 'loop')}
                type="switch"
                label="Loop"
                defaultChecked={settings.params.loop}
              />

              <Form.Check
                onClick={handleChange.bind(this, 'arrows')}
                type="switch"
                label="Arrows"
                defaultChecked={settings.params.arrows}
              />

              {settings.params.arrows === true && (
                <Form.Check
                  onClick={handleChange.bind(this, 'mobileArrows')}
                  type="switch"
                  label="Mobile Arrows"
                  defaultChecked={settings.params.mobileArrows}
                />
              )}
            </>
          )}
        </Form>
      </Container>
    </div>
  );
}
