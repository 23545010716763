import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const WidgetPreview = memo(
  forwardRef(function (props, ref) {
    const {
      widget,
      overrideSettings,
      onChangeState = () => {},
      onWidgetEvent = () => {},
      ...otherProps
    } = props;
    const [renderTime, setRenderTime] = useState(0);
    const [remoteWidget, setRemoteWidget] = useState(null);

    useImperativeHandle(ref, () => ({
      call(...args) {
        remoteWidget && remoteWidget.call(...args);
      }
    }));

    useEffect(() => {
      setRenderTime(Date.now());
      onChangeState(WidgetPreview.State.LOADING);
      return WidgetPreview.listenEvents(event => {
        if (!event.belongsToWidget(widget.widgetId)) {
          return;
        }
        if (event.type === 'mounted') {
          setRemoteWidget(WidgetPreview.getWidget(widget.widgetId, event.source));
          onChangeState(WidgetPreview.State.LOADED);
        }
        onWidgetEvent(event);
      });
    }, [widget.widgetId, onChangeState, onWidgetEvent]);

    if (remoteWidget && overrideSettings) {
      remoteWidget.set(overrideSettings);
    }

    if (!renderTime) {
      return null;
    }

    return (
      <iframe
        title="Preview"
        className="h-100"
        src={`widget-preview.html?widget=${widget.widgetId}&t=${renderTime}`}
        {...otherProps}></iframe>
    );
  })
);

WidgetPreview.State = {
  LOADING: 'loading',
  LOADED: 'loaded'
};

WidgetPreview.Event = class Event {
  static from({ data, source }) {
    if (data && typeof data.event === 'string' && data.event.startsWith('Widgetlist.widget.')) {
      const props = { ...data, source, type: data.event.slice(18) };
      delete props.event;
      return new this(props);
    }
  }
  constructor(props = {}) {
    Object.assign(this, props);
  }
  belongsToWidget(widgetId) {
    return this.widget && this.widget.id === widgetId;
  }
};

WidgetPreview.getWidget = (widgetId, window) => {
  return {
    set(settings) {
      window.postMessage(
        {
          action: 'Widgetlist.widget.update',
          widget: { id: widgetId },
          payload: settings
        },
        '*'
      );
    },
    call(action, payload) {
      action !== 'update' &&
        window.postMessage(
          {
            action: `Widgetlist.widget.${action}`,
            widget: { id: widgetId },
            payload
          },
          '*'
        );
    }
  };
};

WidgetPreview.listenEvents = callback => {
  function handler(message) {
    const event = WidgetPreview.Event.from(message);
    event && callback(event);
  }
  window.addEventListener('message', handler, false);
  return () => window.removeEventListener('message', handler);
};

WidgetPreview.displayName = 'WidgetPreview';
export default WidgetPreview;
