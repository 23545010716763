import { SHOP_TITLE_UPDATE, SHOP_LAYOUT_UPDATE } from './actionTypes';

export default function ShopSettingsReducer(state = {}, action) {
  switch (action.type) {
    case SHOP_TITLE_UPDATE:
      return { ...state, title: action.payload };
    case SHOP_LAYOUT_UPDATE:
      return {
        ...state,
        params: { ...state.params, ...action.payload }
      };
    default:
      return state;
  }
}
