import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import useMediaQuery from '../../hooks/useMediaQuery';
import useProjects from '../../hooks/useProjects';
import { Placeholder } from '../Placeholder/Placeholder';
import NavigationBar from '../../components/builder/Interface/NavigationBar/NavigationBar';
import Preview from '../../components/builder/Preview/Preview';
import PreviewWrapper from '../../components/builder/Preview/PreviewWrapper/PreviewWrapper';
import styles from './AppLayout.module.css';
import { useWidgetSettings } from '../../hooks/useWidgetSettings';

export const AppLayout = () => {
  const isMobile = useMediaQuery({ max: 991 });
  const { activeProject, loadProjects, saveProject } = useProjects();
  const navigate = useNavigate();
  const location = useLocation();
  const { setActiveWidgetSettings } = useWidgetSettings({ context: 'editor' });

  useEffect(() => {
    loadProjects().then(projects => {
      if (projects.length) {
        const searchParams = new URLSearchParams(location.search);
        let [project] = projects;
        const instagramAuthCode = searchParams.get('code');
        if (instagramAuthCode) {
          project = {
            ...project,
            settings: { ...project.settings, title: '@mobirise' },
            widgetId: '0ea9928d-45da-4c03-b725-19209902bf56'
          };
          saveProject(project);
        }
        setActiveWidgetSettings(project);
        navigate(isMobile ? '/builder' : '/home');
        return;
      }
      // navigate('/widget-creator');
    });
  }, []);

  return (
    <div className={styles.root}>
      {isMobile ? (
        <div className="d-flex flex-column justify-content-between h-100">
          <Outlet />
          <NavigationBar />
        </div>
      ) : (
        <div className="d-flex h-100">
          <NavigationBar />
          <Row className="h-100 w-100">
            <Col lg={5}>
              <Outlet />
            </Col>
            <Col lg={7}>
              {activeProject ? (
                <PreviewWrapper>
                  <Preview />
                </PreviewWrapper>
              ) : (
                <Placeholder />
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
