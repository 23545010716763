import React from 'react';
import {
  Facebook,
  Whatsapp,
  Twitter,
  Telegram,
  Linkedin,
  ThreeDots,
  Envelope
} from 'react-bootstrap-icons';
import ShareButton from '../components/builder/Interface/ShareButton/ShareButton';
import styles from '../components/builder/Interface/ShareButton/ShareButton.module.css';
import { ReactComponent as Vk } from '../assets/vk.svg';
import { ReactComponent as Reddit } from '../assets/reddit.svg';
import { ReactComponent as Viber } from '../assets/viber.svg';

export default function useShareButtons() {
  function TwitterButton({ url }) {
    return (
      <ShareButton
        classes={styles.twitter_btn}
        url={'https://twitter.com/intent/tweet?text=Check%20out%20my%20website!&url=' + url}
        icon={<Twitter size={20} />}
        title={'Twitter'}
      />
    );
  }

  function LinkedinButton({ url }) {
    return (
      <ShareButton
        classes={styles.linkedin_btn}
        url={
          'https://www.linkedin.com/sharing/share-offsite/?text=Check%20out%20my%20website!&url=' +
          url
        }
        icon={<Linkedin size={20} />}
        title={'Linkedin'}
      />
    );
  }

  function VkButton({ url }) {
    return (
      <ShareButton
        classes={styles.vk_btn}
        url={'http://vk.com/share.php?url=' + url}
        icon={<Vk size={20} fill={'#fff'} />}
        title={'Vkontakte'}
      />
    );
  }

  function RedditButton({ url }) {
    return (
      <ShareButton
        classes={styles.reddit_btn}
        url={'https://reddit.com/submit?url=' + url}
        icon={<Reddit size={20} fill={'#fff'} />}
        title={'Reddit'}
      />
    );
  }

  function ViberButton({ url }) {
    return (
      <ShareButton
        classes={styles.viber_btn}
        url={'viber://forward?text=' + url}
        icon={<Viber size={20} fill={'#fff'} />}
        title={'Viber'}
      />
    );
  }

  function TelegramButton({ url }) {
    return (
      <ShareButton
        classes={styles.tg_btn}
        url={'https://t.me/share/url?text=Check%20out%20my%20website!&url=' + url}
        icon={<Telegram size={20} />}
        title={'Telegram'}
      />
    );
  }

  function FacebookButton({ url }) {
    return (
      <ShareButton
        classes={styles.fb_btn}
        url={'https://www.facebook.com/sharer/sharer.php?u=' + url}
        icon={<Facebook size={20} />}
        title={'Facebook'}
      />
    );
  }

  function WhatsappButton({ url }) {
    return (
      <ShareButton
        classes={styles.whatsapp_btn}
        url={'https://wa.me/?text=' + url}
        icon={<Whatsapp size={20} />}
        title={'WhatsApp'}
      />
    );
  }

  function SystemShareButton({ url }) {
    return (
      <ShareButton
        classes={styles.system_share_btn}
        url={url}
        action={() => shareViaNavigator(url)}
        icon={<ThreeDots size={20} />}
        title={'Other'}
      />
    );
  }

  function EmailShareButton({ url }) {
    return (
      <ShareButton
        classes={styles.email_share_btn}
        url={'mailto:?subject=My new page&amp;body=Check out this page: ' + url}
        icon={<Envelope size={20} />}
        title={'Email'}
      />
    );
  }

  return {
    FacebookButton,
    WhatsappButton,
    TwitterButton,
    TelegramButton,
    LinkedinButton,
    VkButton,
    RedditButton,
    ViberButton,
    SystemShareButton,
    EmailShareButton
  };
}

async function shareViaNavigator(url) {
  try {
    if (navigator.share) await navigator.share({ url });
  } catch (err) {
    console.log(err);
  }
}
