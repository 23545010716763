import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Popup.module.css';
import { Container, Card, Button } from 'react-bootstrap';
import { Check, X } from 'react-bootstrap-icons';

const Popup = ({ title, onClose, body, scrollable, success, cancel }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.popup_wrapper} bootstrap-app-styles builder-styles`}>
      <Container className={styles.popup_container}>
        <Card className={styles.popup_card}>
          <Card.Header>
            <div className={'d-flex text-center'}>
              <div className={styles.popup_header_title}>
                <h5 className={'mb-0'}>{t(title)}</h5>
              </div>
              <div className={styles.popup_close}>
                <X size={20} onClick={onClose} />
              </div>
            </div>
          </Card.Header>
          <Card.Body className="text-center">{body}</Card.Body>
          {(success || cancel) && (
            <Card.Footer className={'d-flex justify-content-between'}>
              {cancel && (
                <Button variant={'light'} onClick={() => cancel()}>
                  {t('Cancel')}
                </Button>
              )}
              {success && (
                <Button variant={'danger'} onClick={() => success()}>
                  {t('Remove')}
                </Button>
              )}
            </Card.Footer>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default Popup;
