import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import i18n from '../../../locales/i18n';
import { useTranslation } from 'react-i18next';
import TopBar from '../builder/Interface/TopBar/TopBar';
import NavList from '../builder/Interface/NavList/NavList';
import { useNavigate } from 'react-router-dom';
import { Form, Container, Button, Row, Col } from 'react-bootstrap';
import styles from './AppSettings.module.css';
import useNotifications from '../../hooks/useNotifications';
import Loader from '../builder/Interface/Loader/Loader';
import usePlans from '../../hooks/usePlans';
import { DEFAULT_PLAN_ID } from '../../hooks/usePlans';
import usePurchases from '../../hooks/usePurchases';
import PlanInfo from '../plans/PlanInfo';
import { usePageView } from '../../hooks/useAnalytics';

const AppSettings = () => {
  const { signout, lostpassword, getProfile } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const notification = useNotifications();
  const [loading, setLoading] = useState(false);
  const plans = usePlans();
  const { userPlans, checkPlan } = usePurchases();
  const [showPlanInfo, setShowPlanInfo] = useState(false);
  // usePageView('App Settings');

  const selectOptions = [{ language: t('English'), locale: 'en' }];

  function handleSelect(e) {
    e.persist();
    const lng = e.target.value;
    i18n.changeLanguage(lng);
  }

  useEffect(() => {
    getProfile().then(account => {
      setEmail(account.email);
    });
  }, []);

  const LanguageForm = () => {
    return (
      <Form.Select className={styles.Form} defaultValue={i18n.language} onChange={handleSelect}>
        {selectOptions.map((item, i) => {
          return (
            <option key={i} value={item.locale}>
              {item.language}
            </option>
          );
        })}
      </Form.Select>
    );
  };

  const resetPassword = async () => {
    setLoading(true);
    try {
      await lostpassword(email);
      setLoading(false);
      notification.info(
        t(`We've sent the confirmation link to ${email}. Please check your inbox.`)
      );
    } catch (err) {
      setLoading(false);
      notification.error(err.message);
    }
  };

  const getUserPlanTitles = () => {
    return userPlans.length ? userPlans.map(plan => plan.title).join(', ') : 'Free';
  };

  const listProps = [
    {
      content: (
        <React.Fragment>
          <h5 className={styles.option_title}>{t('Contact Us')}</h5>
        </React.Fragment>
      ),
      action: () => {
        navigate('/contact-us');
      }
    },
    {
      content: <LanguageForm />,
      action: () => {}
    },
    {
      content: (
        <React.Fragment>
          <h5 className={styles.option_title}>{t('Reset Password')}</h5>
          <p className={styles.option_subtitle}>{email}</p>
        </React.Fragment>
      ),
      action: resetPassword
    },
    {
      content: (
        <Row>
          <Col>
            <h5 className={styles.option_title}>{t('Subscription Plan')}</h5>
            <p className={styles.option_subtitle}>{getUserPlanTitles()}</p>
          </Col>
          {!checkPlan(DEFAULT_PLAN_ID) && (
            <Col xs={'auto'} className={'d-flex align-items-center'}>
              <Button className={styles.upgrade_btn}>{t('Upgrade')}</Button>
            </Col>
          )}
        </Row>
      ),
      action: () => {
        if (!checkPlan(DEFAULT_PLAN_ID)) {
          return plans.show({
            onError: () => notification.warning(t('Payment Error! Could not upgrade your plan'))
          });
        }
        setShowPlanInfo(true);
      }
    },
    {
      content: t('Logout'),
      action: signout
    }
  ];

  const handleClick = () => {
    navigate('/home');
  };

  return (
    <React.Fragment>
      <div className={`bootstrap-app-styles builder-styles bg-wite`}>
        <TopBar onClick={handleClick}>{t('Account Settings')}</TopBar>
        {loading ? (
          <Loader />
        ) : (
          <Container>
            <NavList>{listProps}</NavList>
          </Container>
        )}
      </div>

      {showPlanInfo && <PlanInfo onClose={() => setShowPlanInfo(false)} />}
    </React.Fragment>
  );
};

export default AppSettings;
