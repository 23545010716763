import React from 'react';
import styles from './Loader.module.css';
import useLoader from '../../hooks/useLoader';
import Loader from '../builder/Interface/Loader/Loader';

export default function AppLoader({ children }) {
  const { isShown } = useLoader();
  return (
    <React.Fragment>
      {children}
      <div className={`${styles.loader} ${isShown() && styles.loader_loading}`}>
        <Loader />
      </div>
    </React.Fragment>
  );
}
