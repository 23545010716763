import { PrivateElement } from './PrivateElement';
import { AppLayout } from './AppLayout/AppLayout';
import AppSettings from '../components/app-settings/AppSettings';
import SiteSettings from '../components/site-settings/SiteSettings';
import Share from '../components/share/Share';
import ContactUs from '../components/contact-us/ContactUs';
import Frame from '../components/frame/Frame';
import Layouts from '../components/layouts';
import Home from '../components/home/Home';
import SiteCreator from '../components/widget-creator/WidgetCreator';
import Login from '../components/login/Login';
import Register from '../components/register/Register';
import Forgot from '../components/forgot/Forgot';
import { BuilderRoute } from './BuilderRoute';

const inAppRoutes = [
  { path: '/app-settings', element: <AppSettings /> },
  { path: '/settings', element: <SiteSettings /> },
  { path: '/share', element: <Share /> },
  { path: '/contact-us', element: <ContactUs /> },
  { path: '/styles', element: <Layouts /> },
  { path: '/app-settings', element: <AppSettings /> },
  { path: '/contact-us', element: <ContactUs /> },
  { path: '/home', element: <Home /> },
  { path: '/widget-creator', element: <SiteCreator /> },
  { path: '/builder', element: <BuilderRoute /> }
];

const freeRoutes = [
  { path: '/login/:userEmail', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/forgot-password', element: <Forgot /> }
];

export const routes = [
  ...freeRoutes.map(route => {
    route.element = <Frame>{route.element}</Frame>;
    return route;
  }),
  {
    path: '/',
    element: <AppLayout />,
    children: [
      ...inAppRoutes.map(route => {
        route.element = <PrivateElement element={route.element} />;
        return route;
      })
    ]
  }
];
