import React from 'react';
import styles from './Notifications.module.css';
import useNotifications from '../../hooks/useNotifications';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Alert } from 'react-bootstrap';

export default function Notification() {
  const { notifications, setNotifications } = useNotifications();
  const isMobile = useMediaQuery({ max: 991 });

  const handleClose = () => {
    setNotifications(notifications.slice(1));
  };

  return notifications.length ? (
    <div className={`${styles.notifications} d-flex`}>
      <Alert
        className={isMobile ? `w-100` : ''}
        variant={notifications[0].variant}
        onClose={handleClose}
        dismissible>
        {notifications[0].message}
      </Alert>
    </div>
  ) : null;
}
