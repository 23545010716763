import { UPDATE_PURCHASES } from './actionTypes';

export default function (state = [], action) {
  switch (action.type) {
    case UPDATE_PURCHASES: {
      return [...action.payload];
    }
    default:
      return state;
  }
}
