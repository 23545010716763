import React from 'react';
import { Row, Col, ListGroup, Container } from 'react-bootstrap';
import styles from './NavList.module.css';

const NavList = ({ children }) => {
  return (
    <ListGroup variant="flush">
      {children.map((item, i) => {
        return (
          <ListGroup.Item key={i} className={styles.nav_item} onClick={item.action}>
            <Row className={'justify-content-center align-items-center'}>
              {item.icon && <Col xs={'auto'}>{item.icon}</Col>}
              <Col>
                {typeof item.content === 'string' ? (
                  <h5 className={'mb-0 ' + styles.title}>{item.content}</h5>
                ) : (
                  item.content
                )}
              </Col>
            </Row>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default NavList;
