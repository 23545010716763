import React, { useCallback } from 'react';
import useWidgetPreview from '../../../hooks/useWidgetPreview';
import useProjects from '../../../hooks/useProjects';
import { useWidgetSettings } from '../../../hooks/useWidgetSettings';
import PreviewWrapper from './PreviewWrapper/PreviewWrapper';

export default function Preview() {
  const { activeProject } = useProjects();
  const { settings } = useWidgetSettings();
  const widget = { ...activeProject, settings: { ...settings } };
  const { WidgetPreview } = useWidgetPreview(widget);

  const onWidgetEvent = useCallback(event => {
    if (event.type === 'click' && event.target && event.target.name === 'title') {
      $title.current && $title.current.focus();
    }
  }, []);

  function saveSettings() {
    alert(
      JSON.stringify({
        title: settings.title,
        params: settings.params
      })
    );
  }

  return <WidgetPreview onWidgetEvent={onWidgetEvent} overrideSettings={settings} />;
}
