import { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useLoader from '../hooks/useLoader';
import { useNavigate } from 'react-router-dom';

export default function Auth({ children }) {
  const loader = useLoader();
  const { authorize } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loader.show();
    authorize()
      .catch(err => {
        console.error(err);
        navigate('/register');
      })
      .finally(() => {
        setIsLoaded(true);
        loader.hide();
      });
    const searchParams = new URLSearchParams(location.search);
    const instagramAuthCode = searchParams.get('code');
    if (!instagramAuthCode) {
      navigate('/register');
    }
  }, []);

  return isLoaded ? children : null;
}
