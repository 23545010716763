import { EDITOR_SHOW, EDITOR_HIDE } from "./actionTypes";

const initialState = {
  shown: false,
  compId: undefined, 
  elementId: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EDITOR_SHOW: {
      const { componentId, elementId } = action.payload;
      return {
        ...state,
        shown: true,
        componentId, 
        elementId
      };
    }
    case EDITOR_HIDE: {
      return {
        ...state,
        shown: false,
        componentId: undefined, 
        elementId: undefined
      };
    }
    default:
      return state;
  }
}
