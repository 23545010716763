// react
import React, { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from '../../hooks/useAuth';
import useNotifications from '../../hooks/useNotifications';
// social
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// styles
import { Button, Container, Form, Row, Col, Image } from 'react-bootstrap';
import { Facebook } from 'react-bootstrap-icons';
import styles from './Register.module.css';
import googleLogo from '../../../app/assets/btn_google.svg';
import { useAnalytics, usePageView } from '../../hooks/useAnalytics';

const Register = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  // usePageView('Register');

  const navigate = useNavigate();
  const location = useLocation();

  const email = useRef(null);
  const password = useRef(null);

  const [formStage, setFormStage] = useState('login');

  // const { sendEvent } = useAnalytics();

  let { from } = location.state || { from: { pathname: '/' } };

  const googleId = process.env.REACT_APP_GOOGLE_AUTH_ID;
  const faceId = process.env.REACT_APP_FACEBOOK_AUTH_ID;

  const { signinByToken, signup, signin } = useAuth();

  const gAuthSuccess = async res => {
    try {
      await signinByToken({
        service: 'google',
        token: res.accessToken
      });
      // sendEvent('login', {
      //   method: 'Google'
      // });
    } catch (err) {
      notification.warning(err.message);
      return;
    }
    navigate(from);
  };

  const gAuthFailure = ({ error }) => {
    if (error !== 'popup_closed_by_user') notification.warning(error);
  };

  const responseFacebook = res => {
    signinByToken({
      service: 'facebook',
      token: res.accessToken
    }).then(
      response => {
        // sendEvent('login', {
        //   method: 'Facebook'
        // });
        navigate(from);
      },
      err => {
        notification.warning(err.message);
      }
    );
  };

  const submitHandler = async e => {
    e.preventDefault();
    if (formStage === 'login') {
      // try {
      //   await signup(email.current.value);
      //   notification.success(
      //     `Electric Blaze Account Created! Check your email. Your account password is sent to ${email.current.value}. If you haven't received the email, please check Spam folder`
      //   );
      // } catch (err) {
      //   console.log(err.message);
      //   if (err.code === 'UserAlreadyExists') {
      navigate(`/login/${email.current.value}`);
      //   return;
      // }
      // }
    }

    // setFormStage('password');
    // if (!password.current.value) return;
    // if (formStage === 'password') {
    //   try {
    //     await signin(email.current.value, password.current.value);
    //     // sendEvent('login', {
    //     //   method: 'Email'
    //     // });
    //   } catch (err) {
    //     notification.warning(err.message);
    //     return;
    //   }
    // }
    // navigate('/widget-creator');
  };

  return (
    <div className="bootstrap-app-styles h-100">
      <div className={'d-flex justify-content-center align-items-center h-100'}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Form onSubmit={e => submitHandler(e)}>
                <h5 className="text-center mb-3">{t('Login/Sign up')}</h5>
                {/* <Row className="form-group mb-3 g-3">
                  <Col>
                    <GoogleLogin
                      className="btn w-100"
                      clientId={googleId}
                      render={renderProps => (
                        <Button
                          className={'w-100 shadow ' + styles.google__btn}
                          variant="white"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}>
                          <img
                            width="24"
                            height="24"
                            src={googleLogo}
                            alt="google logo"
                            className="me-3"
                          />
                          Google
                        </Button>
                      )}
                      onSuccess={gAuthSuccess}
                      onFailure={gAuthFailure}
                      cookiePolicy={'single_host_origin'}
                    />
                  </Col>
                  <Col>
                    <FacebookLogin
                      appId={faceId}
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      disableMobileRedirect={true}
                      render={renderProps => (
                        <Button
                          className={'w-100 shadow ' + styles.facebook__btn}
                          onClick={renderProps.onClick}>
                          <Facebook size={24} className="me-3" />
                          Facebook
                        </Button>
                      )}
                    />
                  </Col>
                </Row>
                <h5 className="text-center mb-3">or with email</h5> */}
                <Form.Group controlId="loginFormEmail" className="mb-3">
                  <Form.Control type="email" placeholder={t('Enter Email')} ref={email} />
                </Form.Group>
                {formStage === 'password' ? (
                  <Form.Group controlId="loginFormPassword" className="mb-3">
                    <Form.Control type="password" placeholder={t('Password')} ref={password} />
                  </Form.Group>
                ) : (
                  ''
                )}
                <Button type="submit" className="w-100">
                  {t('Next')}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Register;
