import Api from '../../api/api';
import { ACCOUNT_AUTHORIZE, ACCOUNT_SIGNOUT } from '../../store/account/actionTypes';
import { useSelector, useDispatch } from 'react-redux';

export default function useAuth() {
  const account = useSelector(state => state.account);
  const dispatch = useDispatch();

  function isAuthorized() {
    return account.id != null;
  }

  function getUser() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          id: 54325,
          verified: true,
          name: 'Paul',
          email: 'somemail@gmail.com',
          purchases: [
            {
              id: 1234,
              status: 'active',
              plan: {
                id: 11,
                status: 'hidden',
                title: '8bio Pro Plan',
                price: 23.88,
                features: [
                  'Analytics',
                  'BlueBadge',
                  'ConnectDomain',
                  'ExtendedSitesLimits',
                  'PremiumStyles'
                ]
              },
              created: '2021-10-27T12:57:27.000Z',
              expired: '2022-10-27T00:00:00.000Z',
              order: {
                id: 933745,
                state: 'paid'
              }
            }
          ]
        });
      }, 2000);
    });
  }

  async function authorize() {
    const account = await getUser();
    dispatch({ type: ACCOUNT_AUTHORIZE, payload: account });
  }

  async function signin(email, password) {
    await Api.signin({ email, password });
    await authorize();
  }

  async function signinByToken({ service, token }) {
    await Api.signinByToken({ service, token });
    await authorize();
  }

  async function signup(email) {
    await Api.signup(email);
  }

  async function signout() {
    await Api.signout();
    dispatch({ type: ACCOUNT_SIGNOUT });
  }

  async function lostpassword(email) {
    await Api.lostpassword(email);
  }

  async function getProfile() {
    const account = await Api.getProfile();
    return account;
  }

  async function getPurchases() {
    const purchases = await Api.getPurchases(account.id);
    return purchases;
  }

  return {
    account,
    isAuthorized,
    authorize,
    signin,
    signinByToken,
    signout,
    lostpassword,
    signup,
    getProfile,
    getPurchases
  };
}

// import React from "react"
// import { Route, Redirect } from "react-router-dom"

// // import PropTypes from "prop-types"

// function getCookie(name) {
//   var matches = document.cookie.match(new RegExp(
//     "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"
//   ));
//   return matches ? decodeURIComponent(matches[1]) : undefined;
// }

// const Authmiddleware = ({
//   component: Component,
//   isAuthProtected,
//   ...rest
// }) => (
//   <Route
//     {...rest}
//     render={props => {
//       if (isAuthProtected && !getCookie("XSRF-TOKEN")) {
//         return (
//           <Redirect
//             to={{ pathname: "/login", state: { from: props.location } }}
//           />
//         )
//       }

//       return (
//         <React.Fragment>
//           <Component {...props} />
//         </React.Fragment>
//       )
//     }}
//   />
// )

// // Authmiddleware.propTypes = {
// //   isAuthProtected: PropTypes.bool,
// //   component: PropTypes.any,
// //   location: PropTypes.object,
// //   layout: PropTypes.any,
// // }

// export default Authmiddleware;
