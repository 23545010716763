import { SHOW_LOADER, HIDE_LOADER } from '../../store/actionTypes';
import { useSelector, useDispatch } from 'react-redux';

export default function useLoader() {
  const loadings = useSelector(state => state.app.loadings);
  const dispatch = useDispatch();
  return {
    isShown() {
      return loadings > 0;
    },
    show() {
      dispatch({ type: SHOW_LOADER });
    },
    hide() {
      dispatch({ type: HIDE_LOADER });
    }
  };
}
