import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useProjects from '../../hooks/useProjects';
import styles from './Share.module.css';
import { Container } from 'react-bootstrap';
import { Fullscreen, Instagram, Globe } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import QrCode from './QrCode';
import ShareButtons from './ShareButtons';
import AddTo from './AddTo';
import { ReactComponent as TikTok } from '../../assets/tik-tok.svg';
import CopyButton from './CopyButton';
import TopBar from '../builder/Interface/TopBar/TopBar';
import NavList from '../builder/Interface/NavList/NavList';
import { usePageView } from '../../hooks/useAnalytics';

const Share = () => {
  const { t } = useTranslation();
  const { activeProject, loadProject } = useProjects();
  const [showQrCode, setShowQrCode] = useState(false);
  const [showAddToIG, setShowAddToIG] = useState(false);
  const [showAddToTikTok, setShowAddToTikTok] = useState(false);
  const [publicUrl, setPublicUrl] = useState('');

  usePageView('Share');

  const shareList = [
    {
      content: t('Open in Browser'),
      icon: <Globe size={22} />,
      action: () => window.open(publicUrl, '_blank')
    },
    {
      content: t('QR-code'),
      icon: <Fullscreen size={22} />,
      action: () => setShowQrCode(true)
    },
    {
      content: t('Add to Instagram'),
      icon: <Instagram size={22} />,
      action: () => setShowAddToIG(true)
    },
    {
      content: t('Add to TikTok'),
      icon: <TikTok size={22} />,
      action: () => setShowAddToTikTok(true)
    }
  ];

  return (
    <React.Fragment>
      <div className={`${styles.Share} bootstrap-app-styles builder-styles`}>
        <TopBar>{t('Share Page')}</TopBar>
        <Container className={'mb-3'}>
          <CopyButton textToCopy={publicUrl} />
        </Container>
        <Container className={'mb-3'}>
          <NavList>{shareList}</NavList>
        </Container>
        <Container className={'mb-3'}>
          <h6 className={'fw-bold text-center mb-3'}>{t('Share to')}</h6>
          <ShareButtons publicUrl={publicUrl} />
        </Container>
      </div>

      {showQrCode && (
        <QrCode projectId={activeProject.id} publicUrl={publicUrl} setShowQrCode={setShowQrCode} />
      )}

      {showAddToIG && (
        <AddTo
          projectId={activeProject.id}
          publicUrl={publicUrl}
          setShowAddTo={setShowAddToIG}
          appName={'Instagram'}
        />
      )}

      {showAddToTikTok && (
        <AddTo
          projectId={activeProject.id}
          publicUrl={publicUrl}
          setShowAddTo={setShowAddToTikTok}
          appName={'TikTok'}
        />
      )}
    </React.Fragment>
  );
};
export default Share;
