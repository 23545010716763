import {
    SHOW_LOADER,
    HIDE_LOADER
} from "./actionTypes";

export default function(state = 0, action) {
    switch (action.type) {
        case SHOW_LOADER:
            return state + 1;
        case HIDE_LOADER:
            return state - 1;
        default:
            return state;
    }
}