import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './UserWidget.module.css';
import { Card, Col, Row } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { useWidgetSettings } from '../../hooks/useWidgetSettings';
import { decodeHTML } from '../../../utils';
import useProjects from '../../hooks/useProjects';

const UserWidget = props => {
  const navigate = useNavigate();
  const { setActiveWidgetSettings } = useWidgetSettings({ context: 'editor' });
  const { setActiveProject } = useProjects();
  const { widget } = props;

  if (!widget) return null;
  if (widget.json) return null;

  const handleClick = () => {
    const { widget } = props;
    setActiveWidgetSettings(widget);
    setActiveProject(widget.id);
    navigate('/home');
  };

  return (
    <Col xs={12} className={'mb-3'}>
      <Card
        className={`d-flex item-list ${styles.project} ${props.active && 'item-list-active'}`}
        onClick={handleClick}>
        <Card.Body>
          <Row className={'align-items-center g-3'}>
            <Col className={styles.info}>
              <Card.Title>{decodeHTML(props.widget.id)}</Card.Title>
            </Col>
            <Col xs={'auto'}>
              <Pencil size={24} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default UserWidget;
