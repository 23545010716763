import React from 'react';
import styles from './Frame.module.css';

export default function Frame({ children }) {
  return (
    <div className={styles.frame_outer}>
      <div className={styles.frame_inner}>{children}</div>
    </div>
  );
}
