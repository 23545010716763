import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TopBar from '../Interface/TopBar/TopBar';
import { useTranslation } from 'react-i18next';
import { ArrowRight } from 'react-bootstrap-icons';
import styles from './EditorPlaceholder.module.css';

const EditorPlaceholder = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="bootstrap-app-styles builder-styles bg-white">
      <TopBar onClick={() => navigate(`/home`)}>{t('Edit Page')}</TopBar>
      <Container className={`d-flex justify-content-center align-items-center ${styles.container}`}>
        <h5 className="text-center lh-lg mb-0 me-2">
          {t('Click on any element on your page to edit it')}
        </h5>
        <ArrowRight size={20} />
      </Container>
    </div>
  );
};

export default EditorPlaceholder;
