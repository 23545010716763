import React from 'react';
import useShareButtons from '../../hooks/useShareButtons';
import { Row, Col } from 'react-bootstrap';

const ShareButtons = ({ publicUrl }) => {
  const Buttons = useShareButtons();

  return (
    <Row className={'m-1 d-flex row-cols-4'}>
      {Object.values(Buttons).map((Button, i) => (
        <Col key={i} className={'p-0 mb-2 text-center'}>
          <Button url={publicUrl} />
        </Col>
      ))}
    </Row>
  );
};

export default ShareButtons;
