import { SET_DEMO_PROJECT, CHANGE_DEMO_STYLE } from './actionTypes';

export default function(state = null, action) {
  switch (action.type) {
    case SET_DEMO_PROJECT: {
      const { project } = action.payload;
      return project;
    }

    case CHANGE_DEMO_STYLE: {
      const skin = action.payload.skin;
      const settings = action.payload.settings;
      state.skin = { name: skin };
      state.components = state.components.map(comp => {
        if (comp.name !== 'Bio') return comp;
        comp.settings = Object.assign(comp.settings, settings);
        return comp;
      })
      return { ...state };
    }

    default:
      return state;
  }
}
