import { Modal } from 'react-bootstrap';
import useModal from '../../hooks/useModal';

export const ModalWindow = () => {
  const { open, title, body, footer, hide } = useModal();
  return (
    <Modal
      show={open}
      onHide={() => hide()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      {body && <Modal.Body>{body}</Modal.Body>}
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};
