import React, { createContext, useContext, useState } from 'react';
import Notification from '../components/notifications/Notification';

const NotificationContext = createContext({
  notifications: [],
  setNotifications: () => {}
});

export default function useNotifications() {
  const { notifications, setNotifications } = useContext(NotificationContext);

  function warning(message) {
    const notification = {
      variant: 'warning',
      message
    };
    setNotifications(state => [notification, ...state]);
  }

  function error(message) {
    const notification = {
      variant: 'danger',
      message
    };
    setNotifications(state => [notification, ...state]);
  }

  function info(message) {
    const notification = {
      variant: 'info',
      message
    };
    setNotifications(state => [notification, ...state]);
  }

  function success(message) {
    const notification = {
      variant: 'success',
      message
    };
    setNotifications(state => [notification, ...state]);
  }

  return {
    notifications,
    setNotifications,
    warning,
    success,
    info,
    error
  };
}

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  const context = {
    notifications,
    setNotifications
  };

  return (
    <NotificationContext.Provider value={context}>
      {children}
      <Notification />
    </NotificationContext.Provider>
  );
}
