import Api from '../../api/api';
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_PURCHASES } from '../../store/actionTypes';
import { useAnalytics } from './useAnalytics';

const PADDLE_VENDOR = process.env.REACT_APP_PADDLE_VENDOR;
const PADDLE_ENVIRONMENT = process.env.REACT_APP_PADDLE_ENVIRONMENT;

let importPaddlePromise;

export default function usePurchases() {
  const account = useSelector(state => state.account);
  const dispatch = useDispatch();
  const { sendEvent } = useAnalytics();
  const purchases = account.purchases;
  const activePurchases = purchases ? purchases.filter(purchase => isActive(purchase)) : [];
  const userPlans = activePurchases.map(purchase => purchase.plan);
  const lastPurchase = activePurchases.reduce((acc, p) => (acc.created > p.created ? acc : p), {});

  async function updatePurchases() {
    dispatch({ type: UPDATE_PURCHASES, payload: await Api.getPurchases(account.id) });
  }

  async function importPaddle() {
    if (!importPaddlePromise) {
      importPaddlePromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.onerror = reject;
        script.onload = () => {
          if (!window.Paddle) {
            return reject(new Error('Failed to load paddle.js'));
          }
          if (PADDLE_ENVIRONMENT) {
            window.Paddle.Environment.set(PADDLE_ENVIRONMENT);
          }
          window.Paddle.Setup({ vendor: Number(PADDLE_VENDOR) });
          resolve(window.Paddle);
        };
        document.body.appendChild(script);
        script.src = 'https://cdn.paddle.com/paddle/paddle.js';
      });
    }
    return importPaddlePromise;
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function waitPurchase(order, { attempts = 4, interval = 3000 } = {}) {
    for (let i = 0; i < attempts; i++) {
      const purchases = await Api.getPurchases(account.id);
      if (purchases.some(purchase => order.id === purchase.order.id)) {
        const params = {
          currency: order.currency,
          transaction_id: String(order.id),
          value: order.price,
          items: [
            {
              item_id: String(order.product.id),
              item_name: order.product.name
            }
          ]
        };
        if (order.coupon) params.coupon = order.coupon;
        sendEvent('purchase', params);
        dispatch({ type: UPDATE_PURCHASES, payload: purchases });
        return;
      }
      await sleep(interval);
    }
    throw Object.assign(
      new Error(
        'Payment has not yet been fully processed, please try again later or contact support'
      ),
      { code: 'PaymentNotProcessed' }
    );
  }

  async function buy(plan) {
    sendEvent('begin_checkout', {
      currency: 'USD',
      value: plan.price,
      items: [
        {
          item_id: String(plan.id),
          item_name: plan.title
        }
      ]
    });
    const Paddle = await importPaddle();
    const order = await Api.createOrder(account.id, plan.id);
    await new Promise((resolve, reject) => {
      Paddle.Checkout.open({
        // https://paddle.com/docs/checkout-parameters/
        title: plan.title,
        message: plan.message,
        product: order.provider.productId,
        email: account.email,
        marketingConsent: '',
        disableLogout: true,
        upsellAction: 'Buy now!',
        successCallback: response => {
          const { checkout, product } = response;
          order.product = product;
          let coupon = checkout.coupon && checkout.coupon.coupon_code;
          if (coupon) order.coupon = coupon;
          order.price = checkout.prices.customer.total;
          order.currency = checkout.prices.customer.currency;
          return resolve();
        },
        closeCallback: () => reject(new Error('Payment error')),
        passthrough: { orderId: order.id, userId: account.id, ownerId: account.id }
      });
    });
    await waitPurchase(order);
  }

  function isActive(purchase) {
    return purchase.status === 'active' && purchase.expired.getTime() > Date.now();
  }

  function checkPaidFeature(feature) {
    return purchases.some(
      purchase => isActive(purchase) && purchase.plan.features.includes(feature)
    );
  }

  function checkPlan(id) {
    return userPlans.some(plan => plan.id === +id);
  }

  return {
    buy,
    updatePurchases,
    purchases,
    checkPaidFeature,
    checkPlan,
    userPlans,
    lastPurchase
  };
}
