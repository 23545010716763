import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../builder/Interface/Loader/Loader';
import TopBar from '../builder/Interface/TopBar/TopBar';
import { ListGroup, Container, Row, Card, Button } from 'react-bootstrap';
import useProjects from '../../hooks/useProjects';
import { useWidgetSettings } from '../../hooks/useWidgetSettings';

const SiteCreator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createProject, getProjects } = useProjects();
  const { setActiveWidgetSettings } = useWidgetSettings({ context: 'editor' });

  const widgets = [
    {
      id: 'instagram',
      title: 'Instagram Feed',
      description: 'Create Instagram Feed for your website.',
      action: async () => {
        const widget = await createProject('instagram');
        setActiveWidgetSettings(widget);
        navigate('/home');
      }
    },
    {
      id: 'shop',
      title: 'Shop',
      description: 'Create Shop for your website.',
      action: async () => {
        const widget = await createProject('shop');
        setActiveWidgetSettings(widget);
        navigate('/home');
      }
    }
  ];

  return (
    <div className="bootstrap-app-styles builder-styles">
      <TopBar onClick={() => navigate('/home')}>{t('Create Widget')}</TopBar>
      <Container className="mt-2">
        {isLoading ? (
          <>
            <p className={'text-center'}>{t('Creating a new page...')}</p>
            <Loader />
          </>
        ) : (
          <Container className={'d-block'}>
            <Row>
              <ListGroup variant="flush" className="w-100 pe-0">
                {widgets.map(widget => (
                  <ListGroup.Item key={widget.id}>
                    <Card className="text-center">
                      <Card.Body>
                        <Card.Title>{widget.title}</Card.Title>
                        <Card.Text>{widget.description}</Card.Text>
                        <Button variant="primary" className="w-100" onClick={widget.action}>
                          Create
                        </Button>
                      </Card.Body>
                    </Card>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Row>
          </Container>
        )}
      </Container>
    </div>
  );
};

export default SiteCreator;
