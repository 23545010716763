import { SELECTOR_SHOW, SELECTOR_HIDE } from './actionTypes';

const initialState = {
  shown: false,
  compId: undefined, 
  elementId: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SELECTOR_SHOW: {
    const { componentId, elementId } = action.payload;
    return {
      ...state,
      shown: true,
      componentId, 
      elementId
    };
  }
  case SELECTOR_HIDE: {
    return {
      ...state,
      shown: false,
      componentId: undefined, 
      elementId: undefined
    };
  }
  default:
    return state;
  }
}
