import React, { useRef } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import Popup from '../popup/Popup';
import { useAnalytics } from '../../hooks/useAnalytics';

const QrPopup = ({ publicUrl, projectId, setShowQrCode }) => {
  const qrRef = useRef(null);
  const { t } = useTranslation();
  const { sendEvent } = useAnalytics();

  const downloadQRCode = () => {
    const svg = qrRef.current.innerHTML;
    const blob = new Blob([svg], { type: 'image/svg+xml' });
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = projectId;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
    sendEvent('share', {
      method: 'QR-code'
    });
  };

  const PopupBody = () => (
    <React.Fragment>
      <Card.Text>{t('You can print your unique QR-code on business cards or flyers')}</Card.Text>
      <div className="pb-4" ref={qrRef}>
        <QRCode value={publicUrl} size={180} />
      </div>
      <Button variant="light" onClick={downloadQRCode}>
        {t('Download QR')}
      </Button>
    </React.Fragment>
  );

  return <Popup title="QR-code" onClose={() => setShowQrCode(false)} body={<PopupBody />} />;
};

export default QrPopup;
