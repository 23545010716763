import React, { useState } from 'react';
import usePlans from '../../hooks/usePlans';
import TopBar from '../builder/Interface/TopBar/TopBar';
import styles from './Plan.module.css';
import { Button, Container, ListGroup, Row, Col } from 'react-bootstrap';
import { Check } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import usePurchases from '../../hooks/usePurchases';
import Loader from '../builder/Interface/Loader/Loader';
import { usePageView } from '../../hooks/useAnalytics';

const Plans = ({ plan, onSuccess, onError }) => {
  const { t } = useTranslation();
  const { shown, hide } = usePlans();
  const { buy } = usePurchases();
  const [loading, setLoading] = useState(false);
  usePageView('Plans');

  const monthlyPrice = () => (plan.price / 12).toFixed(2);
  const dollars = () => String(monthlyPrice()).split('.')[0];
  const cents = () => String(monthlyPrice()).split('.')[1];

  function buyPlan() {
    setLoading(true);
    buy(plan).then(
      () => {
        setLoading(false);
        hide();
        if (onSuccess) onSuccess();
      },
      err => {
        setLoading(false);
        hide();
        console.log(onError);
        if (onError) onError();
        console.error(err);
      }
    );
  }

  const features = [
    t('5 pages, unlimited links'),
    t('Analytics insights'),
    t('Blue badge'),
    t('Premium styles'),
    t('Remove Electric Blaze logo'),
    t('Try risk-free 14 days, cancel anytime')
  ];

  return (
    shown &&
    (loading ? (
      <div className={`bootstrap-app-styles builder-styles ${styles.loading}`}>
        <Loader />
      </div>
    ) : (
      <div className={'bootstrap-app-styles builder-styles ' + styles.plans}>
        <TopBar onClick={() => hide()}>{t('Upgrade to Pro')}</TopBar>
        <Container className={'text-center pb-2'}>
          <span className={'d-flex justify-content-center'}>
            <h1 className={styles.price_round}>{`$${dollars()}`}</h1>
            <span className={styles.price}>
              <span>{`$${cents()}`}</span>
              <span>{'/' + t('month')}</span>
            </span>
          </span>
          <p>{t('paid annually')}</p>
          <ListGroup variant="flush" className={'pt-2 pb-4'}>
            {features.map((item, i) => (
              <ListGroup.Item key={i}>
                <Row className={'justify-content-center align-items-center'}>
                  <Col xs={'auto'}>
                    <Check color={'#3ac267'} size={30} />
                  </Col>
                  <Col className={'p-0'}>
                    <p className={'fs-5 text-start mb-0'}>{item}</p>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button className={'w-100'} onClick={buyPlan}>
            {t('Upgrade')}
          </Button>
        </Container>
      </div>
    ))
  );
};

export default Plans;
