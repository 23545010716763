import { useContext } from 'react';
import { ModalContext } from '../../providers/ModalProvider';

export default function useModal() {
  const { modal, setModal } = useContext(ModalContext);

  function set(modal) {
    setModal({ ...modal, open: true });
  }

  function hide() {
    setModal(prev => ({ ...prev, open: false }));
  }

  return {
    ...modal,
    set,
    hide
  };
}
