import { combineReducers } from 'redux';
// import builder from './builder/reducers';
import blockEditor from './block-editor/reducers';
import imageEditor from './image-editor/reducers';
import videoSelect from './video-select/reducers';
import loadings from './loadings/reducers';
import listEditor from './list-editor/reducers';
import styleSelector from './style-selector/reducers';
import demoProject from './demo-project/reducers';
import widgetSettings from './widget-settings/reducers';

const rootReducer = combineReducers({
  loadings,
  blockEditor,
  imageEditor,
  listEditor,
  styleSelector,
  videoSelect,
  demoProject,
  widgetSettings
});

export default rootReducer;
