export const LOAD_PROJECT = 'LOAD_PROJECT';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const UPDATE_PROJECT_PROPS = 'UPDATE_PROJECT_PROPS';
export const UPDATE_COMPONENT = 'UPDATE_COMPONENT';
export const UPDATE_COMPONENT_ELEMENTS = 'UPDATE_COMPONENT_ELEMENTS';
export const BUILDER_REMOVE_COMPONENT = 'BUILDER_REMOVE_COMPONENT';
export const BUILDER_ADD_COMPONENT = 'BUILDER_ADD_COMPONENT';
export const UPDATE_COMPONENT_ELEMENT = 'UPDATE_COMPONENT_ELEMENT';
export const REMOVE_COMPONENT_ELEMENT = 'REMOVE_COMPONENT_ELEMENT';
export const ADD_COMPONENT_ELEMENT = 'ADD_COMPONENT_ELEMENT';
export const UPDATE_TRACKING_CODE = 'UPDATE_TRACKING_CODE';
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const SAVE_PROJECT_SETTINGS = 'SAVE_PROJECT_SETTINGS';
export const SAVE_PROJECT = 'SAVE_PROJECT';
