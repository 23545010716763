import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import fr from "./fr/translation.json";
import ru from "./ru/translation.json";

// the translations
const resources = {
  fr: {translation: fr},
  ru: {translation: ru}
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    // resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    saveMissing: true,
    // configuration ala gettext
    // https://www.i18next.com/principles/fallback#key-fallback
    fallbackLng: false, // do not load a fallback
    nsSeparator: false, // we do not use keys in form namespace:welcome
    keySeparator: false // we do not use keys in form messages.welcome
  });

export default i18n;