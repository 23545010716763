import React from 'react';
import { useTranslation } from 'react-i18next';
import UserWidget from './UserWidget';
import Loader from '../builder/Interface/Loader/Loader';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Gear } from 'react-bootstrap-icons';
import styles from './Home.module.css';
import { useNavigate } from 'react-router-dom';
import TopBar from '../builder/Interface/TopBar/TopBar';
import NavList from '../builder/Interface/NavList/NavList';
import { usePageView } from '../../hooks/useAnalytics';
import useProjects from '../../hooks/useProjects';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projects, activeProject } = useProjects();

  // usePageView('Home');

  const addSite = () => {
    navigate('/widget-creator');
  };

  const menuList = [
    {
      content: t('Manage Account'),
      icon: <Gear size={24} />,
      action: () => {
        navigate('/app-settings');
      }
    }
  ];

  return (
    <div className={`bootstrap-app-styles builder-styles bg-white`}>
      <TopBar>{t('Home')}</TopBar>
      <Container className="mb-4">
        <h5 className="fw-bold mb-3">{t('Account')}</h5>
        <NavList>{menuList}</NavList>
      </Container>
      <Container>
        <h5 className="fw-bold mb-3">{t('Pages')}</h5>
        {!projects ? (
          <Loader />
        ) : (
          <Row>
            <Col xs={12} className={projects.length && 'mb-3'}>
              <Button variant="primary" size="lg" className={'w-100'} onClick={addSite}>
                {t('Add New Widget')}
              </Button>
            </Col>
            {projects &&
              projects.length > 0 &&
              projects.map(widget => {
                return (
                  <UserWidget
                    key={widget.id}
                    widget={widget}
                    active={(activeProject && activeProject.id) === widget.id}
                  />
                );
              })}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Home;
