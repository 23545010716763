import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './ShareButton.module.css';
import { useAnalytics } from '../../../../hooks/useAnalytics';

const ShareButton = ({ icon, title, url, classes, action }) => {
  const { sendEvent } = useAnalytics();

  function handleClick() {
    sendEvent('share', {
      method: title
    });
    if (action) return action();
    const height = 400;
    const width = 640;
    const top = window.innerHeight - height;
    const left = window.innerWidth - width;
    return window.open(
      url,
      'sharer',
      `width=${width}, 
      height=${height}, 
      top=${top}, 
      left=${left}, 
      toolbar=0, 
      status=0, 
      location=0, 
      menubar=0, 
      directories=0, 
      scrollbars=0`
    );
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        size={'lg'}
        className={`${styles.share_btn} ${classes} d-flex justify-content-center mx-auto`}>
        <div className="d-flex">{icon}</div>
      </Button>
      <p className={`text-center ${styles.share_title}`}>{title}</p>
    </React.Fragment>
  );
};

export default ShareButton;
