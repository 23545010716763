import React from 'react';
import { ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Phone, Tablet, WindowDock } from 'react-bootstrap-icons';
import styles from './ViewSwitch.module.css';

const TopPanel = ({ activeKey, setKey }) => {
  const setVariant = key => `${key === activeKey ? '' : 'outline-'}primary`;

  const options = [
    {
      key: 'phone',
      tooltip: 'Phone View',
      icon: Phone
    },
    {
      key: 'tablet',
      tooltip: 'Tablet View',
      icon: Tablet
    },
    {
      key: 'desktop',
      tooltip: 'Desktop View',
      icon: WindowDock
    }
  ];

  return (
    <div className={styles.wrapper}>
      <ButtonGroup className={styles.buttons}>
        {options.map((item, i) => {
          return (
            <OverlayTrigger
              key={item.key}
              placement={'bottom'}
              overlay={<Tooltip id={`tooltip-bottom`}>{item.tooltip}</Tooltip>}>
              <Button variant={setVariant(item.key)} onClick={() => setKey(item.key)}>
                <item.icon size={24} />
              </Button>
            </OverlayTrigger>
          );
        })}
      </ButtonGroup>
    </div>
  );
};

export default TopPanel;
