import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useProjects from '../../hooks/useProjects';
import TopBar from '../builder/Interface/TopBar/TopBar';
import NavList from '../builder/Interface/NavList/NavList';
import { Trash, Instagram } from 'react-bootstrap-icons';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from '../builder/Interface/Loader/Loader';
import styles from './SiteSettings.module.css';
import Popup from '../popup/Popup';
import { usePageView } from '../../hooks/useAnalytics';
import useModal from '../../hooks/useModal';
import { createInstagramAuthURL } from '../../../utils';

const WidgetSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeProject, removeProject, loadProjects } = useProjects();
  const [loading, setLoading] = useState(false);
  const modal = useModal();
  // usePageView('Widget Settings');

  if (!activeProject) return null;

  const clickOnRemove = () => {
    setLoading(true);
    removeProject(activeProject.id).then(() => {
      setLoading(false);
    });
  };

  const listProps = [
    {
      content: t('Connect to Instagram'),
      icon: <Instagram size={24} />,
      action: () => {
        const instagramAuthURL = createInstagramAuthURL({
          client_id: process.env.REACT_APP_FACEBOOK_AUTH_ID,
          response_type: 'code',
          scope: 'user_profile,user_media',
          redirect_uri: 'https://electricblaze.com/app/',
          state: activeProject.id
        });
        window.location.href = instagramAuthURL;
      }
    },
    {
      content: t('Remove widget'),
      icon: <Trash color={'red'} size={24} />,
      action: () =>
        modal.set({
          title: 'Remove widget',
          body: (
            <div>
              <h5 className={'mb-0'}>{t(`Do you really want to remove the widget`)}?</h5>
            </div>
          ),
          footer: (
            <Button
              onClick={() => {
                modal.hide();
                clickOnRemove();
              }}>
              Remove
            </Button>
          )
        })
    }
  ];

  const popupBody = () => {
    return (
      <div>
        <p className={'mb-0'}>{t(`Do you really want to remove the `)} ?</p>
      </div>
    );
  };

  return (
    <React.Fragment>
      {!loading ? (
        <React.Fragment>
          <div className={`bootstrap-app-styles builder-styles ${styles.root}`}>
            <TopBar>{t('Widget Settings')}</TopBar>
            <Container>
              <NavList>{listProps}</NavList>
            </Container>
          </div>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

export default WidgetSettings;
