// react
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from '../../hooks/useAuth';
import useNotifications from '../../hooks/useNotifications';
// styles
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import styles from './Forgot.module.css';
import { usePageView } from '../../hooks/useAnalytics';

const Login = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  let navigate = useNavigate();
  const email = useRef(null);
  usePageView('Forgot Password');

  const { lostpassword } = useAuth();

  const submitHandler = async e => {
    e.preventDefault();

    try {
      await lostpassword(email.current.value);
      notification.info(t('Check your email'));
    } catch (err) {
      notification.warning(err.message);
      return;
    }

    setTimeout(() => {
      navigate('/login');
    }, 5000);
  };

  return (
    <div className={styles.login__screen + ' bootstrap-app-styles'}>
      <div className={'d-flex justify-content-center align-items-center'}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Form onSubmit={e => submitHandler(e)}>
                <h5 className="text-center mb-3">{t('Forgot Password?')}</h5>

                <Form.Group controlId="loginFormEmail" className={'mb-3'}>
                  <Form.Control type="email" placeholder={t('Enter Email')} ref={email} />
                </Form.Group>
                <Button type="submit" className="w-100">
                  {t('Reset')}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Login;
