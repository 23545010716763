import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AddTo.module.css';
import Popup from '../popup/Popup';
import { Card, Button } from 'react-bootstrap';
import ShareIg from '../../assets/share-ig.jpg';
import ShareTt from '../../assets/share-tt.jpg';
import CopyButton from './CopyButton';
import { useAnalytics } from '../../hooks/useAnalytics';

const AddToInstagram = ({ publicUrl, setShowAddTo, appName }) => {
  const { t } = useTranslation();
  const { sendEvent } = useAnalytics();

  function handleClose() {
    setShowAddTo(false);
  }

  function handleClick() {
    sendEvent('share', {
      method: appName
    });
  }

  const appGuideImg = appName === 'Instagram' ? ShareIg : ShareTt;
  const appLink =
    appName === 'Instagram' ? 'https://www.instagram.com/' : 'https://www.tiktok.com/';

  const PopupBody = () => {
    return (
      <React.Fragment>
        <Card.Text className="text-start">{t('1. Copy your page link')}</Card.Text>
        <CopyButton textToCopy={publicUrl} onCloseDeps={[handleClose]} />
        <Card.Text className="text-start">
          {`${t('2. Open')} ${appName} ${t('and tap on')}`}{' '}
          <span className="fw-bold">{`${t('Edit Profile')}`}</span>
        </Card.Text>
        <Card.Text className="text-start mb-0">
          {t('3. Paste your link to')} <span className="fw-bold">{`${t('Website')}`}</span>{' '}
          {t('field and save changes')}
        </Card.Text>
        {appName === 'TikTok' && (
          <Card.Text className="text-start">
            {t('You will see this field in Business account only')}
          </Card.Text>
        )}
        <Card.Img className={styles.addTo_img} src={appGuideImg} />
        <Button variant="light" target="_blank" href={appLink} onClick={handleClick}>{`${t(
          'Open'
        )} ${appName}`}</Button>
      </React.Fragment>
    );
  };

  return <Popup title={`Add to ${appName}`} onClose={handleClose} body={<PopupBody />} />;
};

export default AddToInstagram;
