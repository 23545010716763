// import defaultProject from '../app/themes/biolink/project';

// import skins from '../app/themes/biolink/skins/skins.js';
// import themesList from '../app/themes/themes-list.js';
import normalizeUrl from 'normalize-url';

const REACT_APP_DEFAULT_THEME = process.env.REACT_APP_DEFAULT_THEME;
const REACT_APP_DEFAULT_SKIN = process.env.REACT_APP_DEFAULT_SKIN;
const SITES_API_URL = process.env.REACT_APP_SITES_API_URL;
const USER_API_URL = process.env.REACT_APP_USER_API_URL;
const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE;
const PRSR_API_URL = process.env.REACT_APP_PRSR_API_URL;
const REACT_APP_RESOURCES_URL = process.env.REACT_APP_RESOURCES_URL;
const PURCHASES_API_URL = process.env.REACT_APP_PURCHASES_API_URL;
const VIDEO_RESOURCES_URL = process.env.REACT_APP_VIDEO_RESOURCES_URL;
const projectsLocation = '@SITES@';

let projectSettings = {};
let projectPath = undefined;

class Api {
  constructor(userApi, sitesApi) {
    this._userApiUrl = userApi;
    this._sitesApiUrl = sitesApi;
    this._authToken = undefined;
    this.waiting = 0;
    this.running = 0;
    this._SitesSettings = {};
    this._selFiles = {};
    this.CACHED_FILES = {};
  }

  // SITES API

  path2aws(path) {
    if (path && path.startsWith(projectsLocation)) {
      path = path.substring(projectsLocation.length + 1).split('/');
      return {
        id: path.shift(),
        path: path.join('/')
      };
    }
  }

  path2app(path) {
    if (!path || /^(\w*\:)?\/\//.test(path)) return path;
    if (path && path.startsWith(projectsLocation)) {
      let aws = this.path2aws(path);
      if (aws && aws.id) {
        return this._SitesSettings && Object.keys(this._SitesSettings).length !== 0
          ? this._SitesSettings.resourcesUrl + aws.path
          : `${REACT_APP_RESOURCES_URL}${aws.id}/${aws.path}`;
      }
    } else {
      return path;
    }
  }

  _REST(resource, options = {}, refreshToken = false) {
    return new Promise((resolve, reject) => {
      this._getAuthToken(refreshToken)
        .then(token => {
          options.headers = Object.assign(options.headers || {}, {
            Authorization: 'Bearer ' + token
          });

          fetch((/^https?:/i.test(resource) ? '' : this._sitesApiUrl) + resource, {
            // fetch(this._sitesApiUrl + resource, {
            method: (options.method || 'GET').toUpperCase(),
            headers: options.headers,
            body: options.data,
            mode: 'cors'
          })
            .then(res => {
              if (res.status >= 200 && res.status < 300) {
                if (options.row) {
                  return res.blob();
                }

                return res.json();
              }

              return res.json().then(data => {
                // check Token is expired
                var msg =
                  (data && data.message) || 'The request to the server could not be completed.';

                if (res.status == 403) {
                  // msg=='Token is expired' &&
                  console.warn(msg);
                  if (!refreshToken) {
                    return this._REST(resource, options, true); // restart with reconnect
                  }

                  //Bridge.emit('auth-error', msg);
                }

                const code = (data && data.type) || (data && data.error && data.error.type);
                throw Object.assign(new Error(msg), code ? { code } : {});
              });
            })
            .then(resolve)
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          console.error(err);
          // Bridge.emit('auth-error', err.message);
          reject(err);
        });
    });
  }

  _getAuthToken(refresh) {
    return new Promise((resolve, reject) => {
      if (!this._authToken || refresh) {
        this.getAuthToken()
          .then(data => {
            resolve((this._authToken = data.access_token));
          })
          .catch(reject);
      } else {
        resolve(this._authToken);
      }
    });
  }

  initProject(callback_error_settings, name) {
    this._REST('sites', { method: 'POST', data: JSON.stringify({ title: name }) })
      .then(data => {
        if (!data || !data.id) throw new Error('Wrong server responce');
        let path = projectsLocation + '/' + data.id;

        data.path = data.path || path;

        this.addRecent(data); // sync here, save as first
        callback_error_settings(0, data);
      })
      .catch(err => callback_error_settings(err));
  }

  getRecentsList(callback_list) {
    return new Promise((resolve, reject) => {
      this._REST('sites')
        .then(data => {
          let list = ((data && data.items) || []).map(item =>
            Object.assign(item || {}, {
              path: projectsLocation + '/' + item.id,
              name: item.title || item.name
            })
          );

          if (!list.length) {
            resolve(list);
            return;
          }

          this.getSettings('_recent', path => {
            if (path) {
              let pos = -1;
              for (let i = 0; i < list.length; i++)
                if (list[i].path == path) {
                  pos = i;
                  break;
                }
              if (pos > 0) list.unshift(list.splice(pos, 1)[0]);
            }

            resolve(list);
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  addRecent(data, callback_ok) {
    if (data && data.path) this.setSettings('_recent', data.path, callback_ok);
    else {
      console.warn('addRecent data is empty');
      if (callback_ok) callback_ok(false);
    }
  }

  saveLocalFile(path, data) {
    return new Promise((resolve, reject) => {
      let aws_path = this.path2aws(path);
      if (aws_path && aws_path.id && aws_path.path) {
        if (data.length > MAX_FILE_SIZE) {
          console.error(
            'File size limit exceeded, max=' + MAX_FILE_SIZE + ', current=' + data.length
          );
          reject();
          return;
        }

        this._REST('sites/' + aws_path.id + '/files/' + aws_path.path, {
          method: 'POST',
          data: data,
          headers: {} // isBase64 ? {'X-Payload-Encoding': 'base64'} : {}
        })
          .then(res => resolve(res && res.success))
          .catch(err => reject(err));
        return;
      }

      console.warn('call saveLocalFile("' + path + '", String[' + data.length + '])'); // ,\n" + data+"\n
      reject();
    });
  }

  _cleanPath(path) {
    let arr = path.split('.'),
      ext = arr[arr.length - 1];
    return path
      .replaceAll(' ', '_')
      .replace(/[(,)]/g, '')
      .replace('jpeg', 'jpg')
      .replaceAll(ext, ext.toLowerCase());
  }

  saveLocalBase64(path, data) {
    return new Promise((resolve, reject) => {
      path = this._cleanPath(path);
      let aws_path = this.path2aws(path);
      if (aws_path && aws_path.id && aws_path.path) {
        const getBase64Code = image => {
          let matches = image.match(/^data:([^;]+);base64,(.+)$/);
          if (matches.length !== 3) {
            reject(Error('Invalid base64 image string'));
            return null;
          }
          return matches[2];
        };

        data = getBase64Code(data);

        if (data.length > MAX_FILE_SIZE * 1.5) {
          reject(
            Error(
              'File size limit exceeded, max=' +
                MAX_FILE_SIZE +
                ', current about ' +
                data.length / 1.5
            )
          );
          return;
        }

        this._REST('sites/' + aws_path.id + '/files/images/' + aws_path.path, {
          method: 'POST',
          data: data,
          headers: { 'X-Payload-Encoding': 'base64' } // isBase64
        })
          .then(res => {
            resolve(res && res.success && `images/${aws_path.path}`);
          })
          .catch(err => reject(err));
        return;
      }

      reject(Error('call saveLocalBase64("' + path + '", String[' + data.length + '])'));
    });
  }

  copyLocal(src, dest) {
    return new Promise((resolve, reject) => {
      this.loadLocalBase64(src, data => {
        if (!data || data.length < 6) {
          reject(new Error('File not found'));
          return;
        }
        // Check file extension (parser generates images wihout extension)
        if (!dest.match(/\.([^/#?]+)([#?][^/]*)?$/gi)) {
          // Get extension from base64 mimeType
          let type = data.match(/[^data:image/](.*)(?=;base64)/)[0];
          // Normalize for api.8b.io
          if (type === 'jpeg') type = 'jpg';
          if (type === 'svg+xml') type = 'svg';
          if (type === 'x-icon' || type === 'vnd.microsoft.icon') type = 'ico';
          if (!['webp', 'jpg', 'png', 'svg', 'gif', 'ico'].includes(type)) {
            reject(new Error('Unsupported image type: ' + type));
            return;
          }
          dest = `${dest}.${type}`;
        }
        this.saveLocalBase64(dest, data)
          .then(path => resolve(path))
          .catch(err => reject(err));
      });
    });
  }

  rmRecent(path) {
    return new Promise((resolve, reject) => {
      var aws_path = this.path2aws(path);
      if (aws_path.id) {
        this._REST('sites/' + aws_path.id, { method: 'DELETE' }).then(res => {
          resolve(res);
        });
      } else reject('wrong site path');
    });
  }

  setSettings(name, value, callback_ok) {
    this.setCookie(name, value);
    if (callback_ok) callback_ok(true);
  }

  getSettings(name, callback_value) {
    if (callback_value) callback_value(this.getCookie(name));
  }

  getCookie(name) {
    var matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setCookie(name, value) {
    document.cookie = name + '=' + encodeURIComponent(value);
  }

  deleteCookie(name) {
    this.setCookie(name, '', {
      'max-age': -1
    });
  }

  getRecentSettings(path, callback_settings) {
    let aws = this.path2aws(path);
    if (aws && aws.id) {
      if (aws.id in this._SitesSettings) return callback_settings(this._SitesSettings[aws.id]);

      this._REST('sites/' + aws.id)
        .then(item => {
          let settings = Object.assign(item || {}, { path: projectsLocation + '/' + item.id });
          settings.name = settings.title || settings.name; // redirect settings
          this._SitesSettings[aws.id] = settings;
          callback_settings(settings);
        })
        .catch(error => callback_settings());
    }
  }

  getLocalJSON(path, callback_data, callback_error) {
    this.loadLocalFile(path, function (text) {
      var data;
      if (text) {
        try {
          data = JSON.parse(text);
        } catch (e) {
          var message = e.message + (e.line ? ' at line ' + e.line : '') + ' in file ' + path;
          console.error(new Error(message, path));
          if (callback_error) callback_error(message);
        }
      }

      if (callback_data) callback_data(data);
    });
  }

  loadLocalFile(path, callback_data) {
    callback_data = callback_data || function () {};

    if (path.endsWith('addons/list.json')) {
      callback_data('');
      return;
    }

    if (path in this.CACHED_FILES) {
      callback_data(this.CACHED_FILES[path]);
      return;
    }

    this._loadFile(path)
      .then(data => {
        let reader = new FileReader();
        reader.onload = function () {
          callback_data(reader.result || '');
        };
        reader.onerror = function () {
          callback_data('');
        };
        reader.readAsText(data); //UTF-8
      })
      .catch(e => {
        callback_data('');
      });
  }

  loadLocalBase64(path, callback_data) {
    callback_data = callback_data || function () {};
    return this._loadFile(path)
      .then(function (data) {
        let reader = new FileReader();
        reader.onload = () => {
          callback_data(reader.result);
        };
        reader.onerror = err => {
          callback_data(err);
        };
        reader.readAsDataURL(data);
      })
      .catch(err => {
        callback_data(err);
      });
  }

  _loadFile(path) {
    if (path instanceof Blob) return Promise.resolve(path);

    if (path in this._selFiles) {
      return Promise.resolve(this._selFiles[path]);
    }

    var aws_path = this.path2aws(path);
    if (aws_path) {
      let ext = this.splitPath(aws_path.path).ext;
      if ('html|xml|css|map|less|js|json|mobirise|svg|'.split('|').includes(ext)) {
        return this._REST('sites/' + aws_path.id + '/files/' + aws_path.path, {
          method: 'GET',
          row: true
        });
      }

      // for binary file use direct fetch - with dynamic cash
      return direct_fetch(Date.now().toString(36));
    }

    if (/^@\w+@/.test(path)) {
      console.warn('call _loadFile("' + path + '")');
      return Promise.resolve(new Blob());
    }

    let _this = this;
    function direct_fetch(ts) {
      let url = _this.path2app(path);
      if (!url.includes('?')) {
        url += '?ts=' + ts;
      }

      return fetch(url, {
        method: 'GET'
        // mode: 'cors'
      })
        .then(res => (res.status != 200 ? new Blob() : res.blob()))
        .catch(err => new Blob());
    }

    return direct_fetch(Date.now().toString(36));
  }

  splitPath(path) {
    path = path.split('/');
    let parts = {};
    let fullname = (parts.fullname = path.splice(path.length - 1, 1)[0]); // last file-path part
    parts.noExt = fullname.indexOf('.') < 0;
    if (parts.noExt) {
      parts.ext = '';
      parts.name = fullname;
    } else {
      parts.ext = fullname.substr(fullname.lastIndexOf('.') + 1);
      parts.name = fullname.substr(0, fullname.lastIndexOf('.'));
    }
    parts.dir = path.join('/');
    return parts;
  }

  loadProject(id) {
    if (id) {
      return new Promise((resolve, reject) => {
        this.getLocalJSON(`@SITES@/${id}/project.json`, project => {
          if (project) {
            project.settings = Object.assign({}, project.settings, id);
            resolve(project, id);
            return;
          }
          reject(`@SITES@/${id}/project.json not found or invalid`);
        });
      });
    } else {
      // if id is undefined the latest project will be loaded
      return new Promise((resolve, reject) => {
        this.getRecentsList()
          .then(recents => {
            let recent = recents && recents[0];
            if (!recent || !recent.path) {
              read();
              return;
            }
            read(recent);
          })
          .catch(err => {
            reject(err);
          });

        const read = settings => {
          if (!settings) {
            //if no projects - create a new one
            this.createProject(Math.floor(new Date().getTime() / 1000))
              .then(project => resolve(project))
              .catch(err => reject(err));
          } else {
            this.getLocalJSON(settings.path + '/project.json', project => {
              if (project) {
                project.settings = Object.assign({}, project.settings, settings);
                resolve(project, settings.id);
                return;
              }
              console.log('project.json not found or invalid');
              // Try to create new project
              read();
            });
          }
        };
      });
    }
  }

  runSaveProject(project, url) {
    let name = project.settings.name || '';
    if (!url) {
      console.error('projectPath is unexpectly empty, while saveProject');
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      this.saveLocalFile(url + '/project.json', JSON.stringify(project))
        .then(dest => {
          if (!dest) {
            console.error('Error to save project to ' + url);
            reject();
            return;
          }
          this.addRecent({ name, path: url }, () => {
            resolve(true);
          });
        })
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  }

  // Accepts the project.
  // Saves all assets to a project folder.
  // Returns a project with new paths.
  // DO NOT USE LARGE video files
  runSaveProjectAssets(project) {
    return new Promise((resolve, reject) => {
      if (!project) return false;
      const assetsProps = ['image', 'video', 'background', 'avatar'];
      let filePromises = [];

      const getAssetsProps = array => {
        array.map(arrayItem => {
          if (arrayItem.settings && arrayItem.settings.list) {
            getAssetsProps(arrayItem.settings.list);
            return;
          }
          Object.keys(arrayItem.settings ? arrayItem.settings : arrayItem).map(name => {
            if (assetsProps.includes(name)) {
              let path = (arrayItem.settings && arrayItem.settings[name]) || arrayItem[name];

              if (!path) return;
              let filename = path.split('/').pop();

              if (!path.indexOf(REACT_APP_RESOURCES_URL) || !path.indexOf(VIDEO_RESOURCES_URL))
                return;

              filePromises.push(
                this.copyLocal(path, project.settings.path + '/' + filename).then(dest => {
                  if (arrayItem.settings) {
                    arrayItem.settings[name] = this.path2app(project.settings.path + '/' + dest);
                  } else {
                    arrayItem[name] = this.path2app(project.settings.path + '/' + dest);
                  }
                })
              );
            }
          });
        });
      };

      getAssetsProps(project.components);

      Promise.all(filePromises)
        .catch(err => {
          console.error(err);
          reject(err);
        })
        .finally(() => {
          resolve(project);
        });
    });
  }

  // getDefaultProject() {
  //   return JSON.parse(JSON.stringify(defaultProject));
  // }

  // createProject(name, currentProject) {
  //   name = String(name);
  //   return new Promise((resolve, reject) => {
  //     if (!currentProject) {
  //       currentProject = this.getDefaultProject();
  //       runCreate.bind(this)();
  //     } else {
  //       if (typeof currentProject != 'object') {
  //         this.loadLocalFile(currentProject, data => {
  //           data = data.replace(
  //             /@PROJECT_PATH@/g,
  //             currentProject.substr(0, currentProject.lastIndexOf('/'))
  //           );
  //           try {
  //             currentProject = JSON.parse(data);
  //           } catch (err) {
  //             reject(err);
  //           }
  //           runCreate.bind(this)();
  //         });
  //       } else runCreate.bind(this)();
  //     }

  //     function runCreate() {
  //       currentProject = currentProject || this.getDefaultProject();
  //       currentProject.settings = { ...currentProject.settings };
  //       currentProject.settings.name = escapeHtml(name);

  //       this.initProject((error, settings) => {
  //         if (error || !settings || !settings.path) {
  //           reject(error || 'Error while create project');
  //           return;
  //         }

  //         currentProject.settings = Object.assign({}, currentProject.settings, settings);

  //         currentProject.settings.developerProject = true;

  //         // set default theme and skin from js array
  //         themesList.forEach(theme => {
  //           if (theme.name === REACT_APP_DEFAULT_THEME) currentProject.name = theme.name;
  //         });

  //         const targetSkin = skins.find(
  //           skin => skin.name === currentProject.skin || skin.name === currentProject.skin.name
  //         );
  //         const defaultSkin = skins.find(skin => skin.name === REACT_APP_DEFAULT_SKIN);
  //         currentProject.skin = targetSkin || defaultSkin;

  //         this._SitesSettings = currentProject.settings;

  //         this.runSaveProject(currentProject, currentProject.settings.path)
  //           .then(project => {
  //             project ? resolve(settings) : reject();
  //           })
  //           .catch(err => reject(err));
  //       }, name);
  //     }
  //   });

  //   function escapeHtml(string) {
  //     var escapeEntityMap = {
  //       '&': '&amp;',
  //       '<': '&lt;',
  //       '>': '&gt;',
  //       '"': '&quot;',
  //       "'": '&#39;',
  //       '/': '&#x2F;'
  //     };

  //     return String(string).replace(/[&<>"'\/]/g, function (s) {
  //       return escapeEntityMap[s];
  //     });
  //   }
  // }

  removeProject(project) {
    return new Promise((resolve, reject) => {
      this.rmRecent(project)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  substProject(rowProject, projectDir) {
    let project = JSON.stringify(rowProject);
    project = project.replace(/@PROJECT_PATH@(\/project\.json)+/g, '@PROJECT_PATH@');
    if (projectDir) {
      project = project.replace(/\@PROJECT_PATH\@/g, projectDir);
      project = project.replace(/\{PROJECT_IMAGES\}/g, projectDir + '/assets/images/');
    }
    try {
      project = JSON.parse(project);
    } catch (e) {
      console.error(e);
      project = rowProject; // back project if error re-convertion
    }
    return project;
  }

  // USER API

  getXSRFToken() {
    return this.getCookie('XSRF-TOKEN') || '';
  }

  getApiUrl() {
    return this._userApiUrl;
  }

  ajax(options = {}) {
    var method = (options.method || 'GET').toUpperCase();
    if (method == 'POST' || method == 'PUT') {
      options.contentType = 'application/json';
      if ('data' in options) options.data = JSON.stringify(options.data);
    }

    return fetch(options.url, {
      credentials: 'include',
      method: method,
      headers: Object.assign(options.headers || {}, {
        'X-XSRF-TOKEN': this.getXSRFToken(),
        'content-type': options.contentType
      }),
      body: options.data
    }).then(
      function (res) {
        return res.text().then(function (data) {
          try {
            data = JSON.parse(data);
          } catch (err) {
            throw new Error('Incorrect server response.');
          }
          if (res.status < 200 || res.status > 299) {
            throw Object.assign(new Error(data.message), { code: data.type });
          }
          return data;
        });
      },
      function () {
        throw new Error('The request to the server could not be completed.');
      }
    );
  }

  signin(options) {
    this._profile = 0;

    return this.ajax({
      url: this.getApiUrl() + 'sessions',
      method: 'POST',
      data: {
        email: options.email,
        password: options.password
      }
    }).then(data => {
      if (data && data.XSRFToken) {
        this.setCookie('XSRF-TOKEN', data.XSRFToken);
        return data;
      } else {
        throw new Error('Incorrect server response.');
      }
    });
  }

  signinByToken({ service, token } = {}) {
    this._profile = 0;

    return this.ajax({
      url: this.getApiUrl() + 'sessions',
      method: 'POST',
      data: { service, token }
    }).then(data => {
      if (data && data.XSRFToken) {
        this.setCookie('XSRF-TOKEN', data.XSRFToken);
        return data;
      } else {
        throw new Error('Incorrect server response.');
      }
    });
  }

  signup(email) {
    return this.ajax({
      url: this.getApiUrl() + 'users',
      method: 'POST',
      data: {
        email: email
      }
    });
  }

  signout() {
    this._profile = 0;
    return this.ajax({
      url: this.getApiUrl() + 'me/session',
      method: 'DELETE'
    }).then(res => {
      this.deleteCookie('XSRF-TOKEN');
      this._authToken = undefined;
    });
  }

  getProfile() {
    var _this = this;

    return new Promise((resolve, reject) => {
      if (_this._profile) {
        resolve(_this._profile);
      } else {
        this.ajax({ url: this.getApiUrl() + 'me' })
          .then(profile => {
            return _this
              .getPurchases(profile.id)
              .catch(err => {
                console.error(err);
                return [];
              })
              .then(purchases => {
                profile.purchases = purchases;
                _this._profile = profile; // no return
                resolve(profile);
              });
          })
          .catch(reject);
      }
    });
  }

  loadPlans() {
    return this._REST(PURCHASES_API_URL + 'plans').then(result => {
      return result.data.reduce((plans, plan) => {
        plans.push({
          id: plan.planId,
          status: plan.planStatus,
          title: plan.planTitle,
          price: plan.planPrice,
          features: plan.features ? plan.features.split(',') : []
        });
        return plans;
      }, []);
    });
  }

  getPurchases(userId) {
    if (!this._subscriptionsPlansPromise) {
      this._subscriptionsPlansPromise = this._REST(PURCHASES_API_URL + 'plans').then(result => {
        return result.data.reduce((plans, plan) => {
          plans[plan.planId] = {
            id: plan.planId,
            status: plan.planStatus,
            title: plan.planTitle,
            price: plan.planPrice,
            features: plan.features ? plan.features.split(',') : []
          };
          return plans;
        }, {});
      });
    }
    return this._subscriptionsPlansPromise.then(plans => {
      return this._REST(PURCHASES_API_URL + `users/${userId}/licenses`).then(result => {
        return result.data.map(license => ({
          id: license.licenseId,
          status: license.licenseStatus,
          plan: plans[license.licensePlanId] || {
            id: license.licensePlanId,
            status: 'archived',
            title: '',
            features: []
          },
          created: new Date(license.licenseCreated),
          expired: new Date(license.licenseExpired),
          order: {
            id: license.orderId,
            state: license.orderState
          }
        }));
      });
    });
  }

  createOrder(userId, planId) {
    return this._REST(PURCHASES_API_URL + `users/${userId}/licenses`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify({ planId, licenseType: 'pay' })
    }).then(({ data }) => ({
      id: data.orderId,
      provider: { productId: data.providerProductId }
    }));
  }

  isAuthorized() {
    return new Promise((resolve, reject) => {
      this.getProfile()
        .then(function () {
          resolve(true);
        })
        .catch(function (err) {
          if (err.code === 403 || err.code === 'AccessDenied') {
            resolve(false);
            return;
          }
          return reject(err);
        });
    });
  }

  getAuthToken() {
    return this.ajax({
      url: this.getApiUrl() + 'tokens',
      method: 'POST',
      data: { target: 'https://8b.io' }
    }).then(data => ({ access_token: data.value }));
  }

  lostpassword(email) {
    return this.ajax({
      url: this.getApiUrl() + 'requests',
      method: 'POST',
      data: { type: 'PasswordReset', params: { email } }
    });
  }

  getTrackingCode(projectId, refreshToken = false) {
    return new Promise((resolve, reject) => {
      this._getAuthToken(refreshToken)
        .then(token => {
          fetch(process.env.REACT_APP_ANALYTICS_API_URL + `website/${projectId}/tracking-code`, {
            method: 'GET',
            mode: 'cors',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `bearer ${token}`
            }
          })
            .then(res => {
              if (res.status >= 200 && res.status < 300) return res.json();
              if (res.status === 403 && !refreshToken) return this.getTrackingCode(projectId, true); // restart with reconnect
              return res.text().then(msg => {
                throw new Error(msg);
              });
            })
            .then(resolve)
            .catch(err => {
              console.error(err);
              reject(err);
            });
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  }

  fetchAnalyticsApi(method, url, body, headers, refreshToken = false) {
    return new Promise((resolve, reject) => {
      this._getAuthToken(refreshToken)
        .then(token => {
          fetch(url, {
            mode: 'cors',
            method,
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `bearer ${token}`,
              ...headers
            },
            body
          })
            .then(res => {
              if (res.status >= 200 && res.status < 300) return res;
              if (res.status === 403 && !refreshToken)
                return this.fetchAnalyticsApi(method, url, body, headers, true); // restart with reconnect
              return res.text().then(msg => {
                throw new Error(msg);
              });
            })
            .then(resolve)
            .catch(err => {
              console.error(err);
              reject(err);
            });
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  }

  getUrlInfo(url) {
    return this._REST(PRSR_API_URL + '/v1/requests', {
      method: 'POST',
      data: JSON.stringify({
        type: 'DataExtract',
        params: { source: normalizeUrl(url, { defaultProtocol: 'https:', stripWWW: false }) }
      })
    })
      .then(data => {
        if (!(data && data.status === 'resolved')) {
          throw new Error((data.reason && data.reason.message) || 'Wrong server responce');
        }
        return data.result;
      })
      .catch(err => {
        throw new Error(err.message || 'request error');
      });
  }

  getUrlInfoWithoutAuth(url) {
    return new Promise((resolve, reject) => {
      fetch(PRSR_API_URL + '/v1/requests', {
        method: 'POST',
        body: JSON.stringify({
          type: 'DataExtract',
          params: { source: normalizeUrl(url, { defaultProtocol: 'https:', stripWWW: false }) }
        })
      })
        .then(res => res.json())
        .then(data => {
          if (data.status === 'rejected') {
            let error = Object.assign(new Error(data.reason.message), { code: data.reason.code });
            reject(error);
          }
          resolve(data.result);
        })
        .catch(err => reject(err));
    });
  }

  changeProjectTitle(projectId, title) {
    return this._REST('sites/' + projectId, {
      method: 'PUT',
      data: JSON.stringify({ title })
    });
  }

  // Domains manipulations
  connectUserDomain(userDomain, siteId) {
    return this._REST(`sites/${siteId}/domains`, {
      method: 'POST',
      data: JSON.stringify({ name: userDomain })
    });
  }

  deleteUserDomain(siteId, dmnId) {
    return this.infoUserDomain(siteId).then(
      res => {
        if (!res) throw new Error('Invalid site information');
        // ???siteId.domains[1].id
        return this._REST(`sites/${siteId}/domains/${dmnId}`, { method: 'DELETE' });
      },
      err => {
        throw new Error(err);
      }
    );
  }

  changeUserDomain(projectId, newDomain) {
    return this._REST(`sites/${projectId}/domains/0`, {
      method: 'PUT',
      data: JSON.stringify({ name: newDomain + '.8b.io' })
    });
  }

  infoUserDomain(siteId) {
    return this._REST(`sites/${siteId}`, { method: 'GET' });
  }

  createWidget(name) {
    return new Promise(resolve => {
      const widget = {
        widgetId: '8e2b3ef0-9fbc-4e65-aa72-7621f3290ec1',
        id: Math.floor(new Date().getTime() / 1000),
        app: {
          name: 'instagram-feed'
        },
        settings: {
          title: '@karlyaspers1',
          params: {
            type: 'gallery',
            top: '3',
            bottom: '3',
            spacing: true,
            gap: 3,
            rounded: true,
            columns: 4,
            rows: 1,
            captions: true,
            showMoreButton: false,
            autoPlay: 0,
            loop: false,
            shadow: true
          }
        }
      };
      resolve(widget);
    });
  }
}

//export { getCookie, setCookie, deleteCookie };
export default new Api(USER_API_URL, SITES_API_URL);
