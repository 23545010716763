import React from 'react';
import { Spinner } from 'react-bootstrap';

import styles from './Loader.module.css';

const Loader = () => {
  return (
    <div className={styles.Loader}>
      <div className={styles.LoaderWrapper + ' d-flex justify-content-center align-items-center'}>
        <Spinner animation="border" variant="primary" className={''} />
      </div>
    </div>
  );
};

export default Loader;
