import {
  LOAD_PROJECT,
  LOAD_PROJECTS,
  ADD_PROJECT,
  REMOVE_PROJECT,
  UPDATE_COMPONENT,
  UPDATE_COMPONENT_ELEMENT,
  UPDATE_COMPONENT_ELEMENTS,
  BUILDER_REMOVE_COMPONENT,
  REMOVE_COMPONENT_ELEMENT,
  ADD_COMPONENT_ELEMENT,
  BUILDER_ADD_COMPONENT,
  UPDATE_TRACKING_CODE,
  UPDATE_PROJECT_PROPS,
  SET_ACTIVE_PROJECT,
  SAVE_PROJECT_SETTINGS,
  SAVE_PROJECT
} from './actionTypes';

export default function (state = [], action) {
  switch (action.type) {
    case LOAD_PROJECT: {
      const isFound = state.some(project => project.id === action.project.id);
      const projects = isFound
        ? state.map(project => (project.id === action.project.id ? action.project : project))
        : [{ ...action.project }, ...state];
      return Object.assign(projects, { all: state.all });
    }

    case UPDATE_PROJECT_PROPS: {
      const { projectId, props } = action.payload;
      const projects = state.map(project => {
        if (project.id === +projectId)
          props.forEach(({ prop, value }) => {
            project[prop] = value;
            if (project.json && project.json.settings[prop]) project.json.settings[prop] = value;
          });
        return project;
      });
      return Object.assign(projects, { all: state.all });
    }

    case LOAD_PROJECTS: {
      const map = state.reduce((map, project) => {
        map[project.id] = project;
        return map;
      }, {});
      const projects = action.payload.map(project => map[project.id] || project);
      return Object.assign(projects, { all: true });
    }

    case ADD_PROJECT: {
      const projects = [{ ...action.payload }, ...state];
      return Object.assign(projects, { all: state.all });
    }

    case REMOVE_PROJECT:
      return Object.assign(
        state.filter(project => project.id !== action.projectId),
        { all: state.all }
      );

    case UPDATE_TRACKING_CODE: {
      const projects = state.map(project => {
        if (project.id === Number(action.payload.id)) {
          project.json.trackingCode = action.payload.trackingCode;
          return { ...project };
        }
        return project;
      });
      return Object.assign(projects, { all: state.all });
    }

    case UPDATE_COMPONENT: {
      const change = action.payload.change;
      const replace = action.payload.replace;
      const projects = state.map(project => {
        if (project.id === Number(action.payload.id)) {
          project.json.components.map(el => {
            if (el.id === action.payload.compId) {
              if (change) {
                if (typeof el.settings[change.prop] === 'object') {
                  el.settings[change.prop][change.name] = change.value;
                } else {
                  el.settings[change.prop] = change.value;
                }
              }
              if (replace) {
                el[replace.prop] = replace.obj;
              }
            }
          });
          return {
            ...project
          };
        }
        return project;
      });
      return Object.assign(projects, { all: state.all });
    }

    case BUILDER_ADD_COMPONENT: {
      const projects = state.map(project => {
        if (project.id === Number(action.payload.id)) {
          project.json.components.push(action.payload.component);
          return { ...project };
        }
        return project;
      });
      return Object.assign(projects, { all: state.all });
    }

    case BUILDER_REMOVE_COMPONENT: {
      const projects = state.map(project => {
        if (project.id === Number(action.payload.id)) {
          project.json.components = project.json.components.filter(
            el => el.id !== action.payload.elementId
          );
          return { ...project };
        }
        return project;
      });
      return Object.assign(projects, { all: state.all });
    }

    case UPDATE_COMPONENT_ELEMENT: {
      const change = action.payload.change;
      const replace = action.payload.replace;
      const projects = state.map(project => {
        if (project.id === Number(action.payload.id)) {
          project.json.components.map(component => {
            let elements = component.settings.list;
            if (component.id === action.payload.compId && elements) {
              elements = elements.map(el => {
                if (el.id === action.payload.elementId) {
                  if (replace) {
                    Object.keys(replace).map(key => {
                      el[key] = replace[key];
                    });
                  } else if (change) {
                    if (typeof el[change.prop] === 'object') {
                      el[change.prop][change.name] = change.value;
                    } else {
                      el[change.prop] = change.value;
                    }
                  }
                }
              });
            }
          });
          return {
            ...project
          };
        }
        return project;
      });
      return Object.assign(projects, { all: state.all });
    }

    case UPDATE_COMPONENT_ELEMENTS: {
      const { projectId, compId, elements } = action.payload;
      const projects = state.map(project => {
        if (project.id === Number(projectId)) {
          project.json.components.map(component => {
            if (component.id === compId) {
              component.settings.list = elements;
            }
          });
          return {
            ...project
          };
        }
        return project;
      });
      return Object.assign(projects, { all: state.all });
    }

    case REMOVE_COMPONENT_ELEMENT: {
      const projects = state.map(project => {
        const { projectId, compId, elementId } = action.payload;
        if (project.id === Number(projectId)) {
          project.json.components.map(component => {
            if (component.id === compId) {
              component.settings.list = component.settings.list.filter(el => el.id !== elementId);
            }
          });
          return { ...project };
        }
        return project;
      });
      return Object.assign(projects, { all: state.all });
    }

    case ADD_COMPONENT_ELEMENT: {
      const projects = state.map(project => {
        const { projectId, compId, element } = action.payload;
        if (project.id === Number(projectId)) {
          project.json.components.map(component => {
            if (component.id === compId) {
              component.settings.list.push(element);
            }
          });
          return { ...project };
        }
        return project;
      });
      return Object.assign(projects, { all: state.all });
    }

    case SET_ACTIVE_PROJECT: {
      const id = action.payload;
      const projects = [...state];
      projects.sort((x, y) => {
        switch (id) {
          case x.id:
            return -1;
          case y.id:
            return 1;
          default:
            return 0;
        }
      });
      return Object.assign(projects, { all: state.all });
    }

    case SAVE_PROJECT_SETTINGS: {
      const { projectId, settings } = action.payload;
      const projects = state.map(project => {
        if (project.id === projectId) {
          project.settings = { ...project.settings, ...settings };
        }
        return { ...project };
      });
      return Object.assign(projects, { all: state.all });
    }

    case SAVE_PROJECT: {
      const targetProject = action.payload;
      const projects = state.map(project => {
        if (project.id === targetProject.id) {
          project = targetProject;
        }
        return { ...project };
      });
      return Object.assign(projects, { all: state.all });
    }

    default:
      return state;
  }
}
