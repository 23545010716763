import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import styles from './TopBar.module.css';
import useProjects from '../../../../hooks/useProjects';
import useMediaQuery from '../../../../hooks/useMediaQuery';

const TopBar = ({ children, onClick }) => {
  const { projects } = useProjects();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ max: 991 });

  const handleClick = () => {
    if (onClick) return onClick();
    const targetPath = isMobile ? '/builder' : '/home';
    if (projects.length && !window.location.hash.includes(targetPath)) {
      navigate(targetPath);
    }
  };

  return (
    <Navbar className={'flex-wrap'}>
      <Nav className={'p-3 w-100 justify-content-center'}>
        <Nav.Link className={styles.arrow} onClick={handleClick}>
          <ArrowLeft className="me-2" size={24} />
        </Nav.Link>
        <Nav.Item>
          <h5 className={'mb-0'}>{children}</h5>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

export default TopBar;
