import Api from '../../api/api';
import { useSelector, useDispatch } from 'react-redux';
import {
  LOAD_PROJECTS,
  LOAD_PROJECT,
  ADD_PROJECT,
  REMOVE_PROJECT,
  UPDATE_PROJECT_PROPS,
  SET_ACTIVE_PROJECT,
  SAVE_PROJECT_SETTINGS,
  SAVE_PROJECT
} from '../../store/actionTypes';

export default function useProjects() {
  const projects = useSelector(state => state.account.projects);
  const dispatch = useDispatch();
  const activeProject = (projects ?? []).at(0);

  function getProject(projectId) {
    return projects.find(item => item.id === Number(projectId));
  }

  async function saveProjectSettings(projectId, settings) {
    // some async logic here
    if (activeProject) dispatch({ type: SAVE_PROJECT_SETTINGS, payload: { projectId, settings } });
  }

  async function loadProject(projectId) {
    return projects.find(item => item.id === projectId);
    // let project = projects.find(item => item.id === Number(projectId));
    // if (project) return project;
    // project = getProject(projectId) || {};
    // try {
    //   project.json = await Api.loadProject(+projectId);
    // } catch (err) {
    //   if (err.code !== 'NotFound') throw err;
    //   project.json = {};
    // }
    // dispatch({ type: LOAD_PROJECT, project });
    // return project;
  }

  async function loadProjects() {
    // const actualProjects = await Api.loadProjects();
    const widget = {
      widgetId: '8e2b3ef0-9fbc-4e65-aa72-7621f3290ec1',
      id: Math.floor(new Date().getTime() / 1000),
      app: {
        name: 'instagram-feed'
      },
      settings: {
        title: '@karlyaspers1',
        params: {
          type: 'gallery',
          top: '3',
          bottom: '3',
          spacing: true,
          gap: 3,
          rounded: true,
          columns: 4,
          rows: 1,
          captions: true,
          showMoreButton: false,
          autoPlay: 0,
          loop: false,
          shadow: true
        }
      }
    };
    const projs = [widget];
    dispatch({ type: LOAD_PROJECTS, payload: projs });
    return projs;
  }

  async function getProjects() {
    if (projects.all === true) return projects;
    return await loadProjects();
  }

  async function createProject(name) {
    const project = await Api.createWidget(name);
    dispatch({ type: ADD_PROJECT, payload: project });
    return project;
  }

  async function getUrlInfo(url) {
    return await Api.getUrlInfo(url);
  }

  function isUrlValid(url) {
    return !!url.match(
      /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi
    );
  }

  async function saveProject(project, url) {
    try {
      // await Api.runSaveProjectAssets(project);
      // await Api.runSaveProject(project, url);
      dispatch({ type: SAVE_PROJECT, payload: project });
      console.log('project saved');
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function removeProject(projectId) {
    // await Api.removeProject(projectId);
    dispatch({ type: REMOVE_PROJECT, projectId });
  }

  async function changeProjectTitle(projectId, title) {
    await Api.changeProjectTitle(projectId, title);
    dispatch({
      type: UPDATE_PROJECT_PROPS,
      payload: { projectId, props: [{ prop: 'title', value: title }] }
    });
  }

  async function loadPlans() {
    const plans = await Api.loadPlans();
    return plans;
  }

  function setActiveProject(projectId) {
    dispatch({
      type: SET_ACTIVE_PROJECT,
      payload: projectId
    });
  }

  function saveProjectSettings(projectId, settings) {
    dispatch({
      type: SAVE_PROJECT_SETTINGS,
      payload: {
        projectId,
        settings
      }
    });
  }

  return {
    projects: projects && projects.all === true ? projects : null,
    activeProject,
    setActiveProject,
    loadProject,
    loadProjects,
    getProject,
    getProjects,
    createProject,
    saveProject,
    removeProject,
    getUrlInfo,
    isUrlValid,
    changeProjectTitle,
    loadPlans,
    saveProjectSettings
  };
}
