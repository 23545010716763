// react
import React, { useRef } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from '../../hooks/useAuth';
import useNotifications from '../../hooks/useNotifications';
// socials
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// styles
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import { Facebook } from 'react-bootstrap-icons';
import styles from './Login.module.css';
import googleLogo from '../../../app/assets/btn_google.svg';
import { useAnalytics, usePageView } from '../../hooks/useAnalytics';

const Login = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  // usePageView('Login');

  const navigate = useNavigate();
  const location = useLocation();
  const { userEmail } = useParams();
  const { from } = location.state || { from: { pathname: '/' } };

  const email = useRef(null);
  const password = useRef(null);

  // const { sendEvent } = useAnalytics();

  const googleId = process.env.REACT_APP_GOOGLE_AUTH_ID;
  const faceId = process.env.REACT_APP_FACEBOOK_AUTH_ID;

  // const { signin, signinByToken } = useAuth();

  const gAuthSuccess = async res => {
    signinByToken({
      service: 'google',
      token: res.accessToken
    })
      .then(
        response => {
          // sendEvent('login', {
          //   method: 'Google'
          // });
          navigate(from);
        },
        err => {
          notification.warning(err.message);
        }
      )
      .finally();
  };

  const gAuthFailure = ({ error }) => {
    if (error !== 'popup_closed_by_user') notification.warning(error);
  };

  const responseFacebook = res => {
    signinByToken({
      service: 'facebook',
      token: res.accessToken
    })
      .then(
        response => {
          // sendEvent('login', {
          //   method: 'Facebook'
          // });
          navigate(from);
        },
        err => {
          notification.warning(err.message);
        }
      )
      .finally();
  };

  const submitHandler = async e => {
    e.preventDefault();
    if (email.current.value === 'test@example.com' && password.current.value === 'test') {
      navigate('/home');
    }
  };

  return (
    <div className="bootstrap-app-styles h-100">
      <div className={'d-flex justify-content-center align-items-center h-100'}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Form onSubmit={e => submitHandler(e)}>
                <h5 className="text-center">{t('Login')}</h5>
                {/* <h5 className="text-center">{t('Login with')}</h5>
                <Row className="form-group mb-3 g-3">
                  <Col>
                    <GoogleLogin
                      className="btn w-100"
                      clientId={googleId}
                      render={renderProps => (
                        <Button
                          className={'w-100 shadow ' + styles.google__btn}
                          variant="white"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}>
                          <img
                            width="24"
                            height="24"
                            src={googleLogo}
                            alt="google logo"
                            className="me-2"></img>
                          Google
                        </Button>
                      )}
                      onSuccess={gAuthSuccess}
                      onFailure={gAuthFailure}
                      cookiePolicy={'single_host_origin'}
                    />
                  </Col>
                  <Col>
                    <FacebookLogin
                      appId={faceId}
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      disableMobileRedirect={true}
                      render={renderProps => (
                        <Button
                          className={'w-100 shadow ' + styles.facebook__btn}
                          onClick={renderProps.onClick}>
                          <Facebook size={24} className="me-2" />
                          Facebook
                        </Button>
                      )}
                    />
                  </Col>
                </Row>
                <h5 className="text-center mb-3">{t('or')}</h5> */}
                <Form.Group controlId="loginFormEmail" className={'mb-3'}>
                  <Form.Control
                    type="email"
                    placeholder={t('Enter Email')}
                    ref={email}
                    onChange={e => {}}
                    value={userEmail || ''}
                  />
                </Form.Group>
                <Form.Group controlId="loginFormPassword" className={'mb-3'}>
                  <Form.Control type="password" placeholder={t('Password')} ref={password} />
                </Form.Group>
                <Form.Group className={'mb-3'}>
                  <Button type="submit" className="w-100">
                    {t('Log In')}
                  </Button>
                </Form.Group>
                <div className="text-center">
                  <Link to="/register">{t('Sign Up')}</Link>
                  {' / '}
                  <Link to="/forgot-password">{t('Forgot Password?')}</Link>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Login;
