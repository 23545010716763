import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth from './components/Auth';
import Loader from './components/loader/Loader';
import { NotificationProvider } from './hooks/useNotifications';
import { AnalyticsProvider } from './hooks/useAnalytics';
import { PlansProvider } from './hooks/usePlans';
import { Routes } from './routing';
import { ModalProvider } from '../providers/ModalProvider';
import './App.scss';

const App = () => {
  return (
    <ModalProvider>
      <NotificationProvider>
        <Loader>
          <Router basename="app">
            <Auth>
              {/* <AnalyticsProvider>
                <PlansProvider> */}
              <Routes />
              {/* </PlansProvider>
              </AnalyticsProvider> */}
            </Auth>
          </Router>
        </Loader>
      </NotificationProvider>
    </ModalProvider>
  );
};

export default App;
