import React, { Fragment, useState, useEffect, useCallback } from 'react';
import styles from './PreviewWrapper.module.css';
import ViewSwitch from '../ViewSwitch/ViewSwitch';
import useProjects from '../../../../hooks/useProjects';

const Preview = ({ children }) => {
  const [activeKey, setKey] = useState('desktop');
  const { activeProject } = useProjects();

  if (!activeProject) return null;

  return (
    <Fragment>
      <ViewSwitch {...{ activeKey, setKey }} />
      <div
        className={`d-flex justify-content-center align-items-center ${styles.wrapper} ${
          styles[activeKey] || ''
        }`}>
        {children}
      </div>
    </Fragment>
  );
};

export default Preview;
