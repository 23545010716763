import {
  ACCOUNT_AUTHORIZE,
  ACCOUNT_SIGNOUT,
  LOAD_PROJECT,
  LOAD_PROJECTS,
  ADD_PROJECT,
  REMOVE_PROJECT,
  UPDATE_COMPONENT,
  UPDATE_COMPONENT_ELEMENTS,
  UPDATE_COMPONENT_ELEMENT,
  REMOVE_COMPONENT_ELEMENT,
  ADD_COMPONENT_ELEMENT,
  BUILDER_REMOVE_COMPONENT,
  BUILDER_ADD_COMPONENT,
  UPDATE_TRACKING_CODE,
  UPDATE_PROJECT_PROPS,
  UPDATE_PURCHASES,
  SAVE_PROJECT_SETTINGS,
  SET_ACTIVE_PROJECT,
  SAVE_PROJECT
} from './actionTypes';
import projectsReducers from './projects/reducers';
import purchasesReducers from './purchases/reducers';

export default function (state = {}, action) {
  switch (action.type) {
    case ACCOUNT_AUTHORIZE: {
      return {
        ...action.payload,
        projects: []
      };
    }

    case ACCOUNT_SIGNOUT: {
      return {
        ...action.payload,
        projects: []
      };
    }

    case LOAD_PROJECT:
    case UPDATE_PROJECT_PROPS:
    case LOAD_PROJECTS:
    case ADD_PROJECT:
    case REMOVE_PROJECT:
    case UPDATE_COMPONENT:
    case UPDATE_COMPONENT_ELEMENTS:
    case UPDATE_COMPONENT_ELEMENT:
    case REMOVE_COMPONENT_ELEMENT:
    case ADD_COMPONENT_ELEMENT:
    case BUILDER_REMOVE_COMPONENT:
    case BUILDER_ADD_COMPONENT:
    case UPDATE_TRACKING_CODE:
    case SAVE_PROJECT_SETTINGS:
    case SET_ACTIVE_PROJECT:
    case SAVE_PROJECT:
      return {
        ...state,
        projects: projectsReducers(state.projects, action)
      };

    case UPDATE_PURCHASES:
      return {
        ...state,
        purchases: purchasesReducers(state.purchases, action)
      };

    default:
      return state;
  }
}
