import React, { useState, useCallback, useMemo } from 'react';
import Preview from '../components/builder/Preview/WidgetPreview';

export default function useWidgetPreview(widget) {
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const onChangeState = useCallback(
    state => setIsPreviewLoading(state === Preview.State.LOADING),
    []
  );

  const WidgetPreview = useCallback(
    (props = {}) => {
      return <Preview widget={widget} onChangeState={onChangeState} {...props} />;
    },
    [onChangeState, widget?.widgetId]
  );

  const remoteWidget = useMemo(() => {
    return new Proxy(
      {},
      {
        get(_, action) {
          return payload =>
            remoteWidgetRef.current && remoteWidgetRef.current.call(action, payload);
        }
      }
    );
  }, []);

  return { WidgetPreview, remoteWidget, isPreviewLoading };
}
