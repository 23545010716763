import { useState, createContext, useContext, useEffect } from 'react';
import Plan from '../components/plans/Plan';
import useProjects from './useProjects';

const PlansContext = createContext();
export const DEFAULT_PLAN_ID = 11;
export const DEFAULT_PLAN_TITLE = 'Electric Blaze Pro Plan';
export const DEFAULT_PLAN_PRICE = 23.88;

const usePlans = () => {
  const { shown, setShown, plans, setPlan, setActions } = useContext(PlansContext);
  return {
    shown,
    show: props => {
      let id = props && props.id ? props.id : DEFAULT_PLAN_ID;
      const targetPlan = (plans && plans.find(plan => plan.id == id)) || initialState;
      props && props.onSuccess
        ? setActions(prev => Object.assign(prev, { onSuccess: props.onSuccess }))
        : setActions(prev => Object.assign(prev, { onSuccess: () => {} }));
      props && props.onError
        ? setActions(prev => Object.assign(prev, { onError: props.onError }))
        : setActions(prev => Object.assign(prev, { onError: () => {} }));
      setPlan(targetPlan);
      setShown(true);
    },
    hide: () => setShown(false)
  };
};

const initialState = {
  id: DEFAULT_PLAN_ID,
  title: DEFAULT_PLAN_TITLE,
  price: DEFAULT_PLAN_PRICE
};

export const PlansProvider = ({ children }) => {
  const [shown, setShown] = useState(false);
  const [plan, setPlan] = useState();
  const [plans, setPlans] = useState();
  const [actions, setActions] = useState({
    onSuccess: () => {},
    onError: () => {}
  });
  const { loadPlans } = useProjects();

  useEffect(() => {
    loadPlans()
      .then(plans => {
        setPlans(plans);
        const defaultPlan = plans.find(plan => plan.id === DEFAULT_PLAN_ID);
        setPlan(defaultPlan);
      })
      .catch(err => {
        setPlan(initialState);
        console.log(err);
      });
  }, []);

  const context = { shown, plans, setShown, setPlan, setActions };

  return (
    <PlansContext.Provider value={context}>
      {children}
      {shown && <Plan plan={plan} onSuccess={actions.onSuccess} onError={actions.onError} />}
    </PlansContext.Provider>
  );
};

export default usePlans;
