import { VIDEO_SELECT_SHOW, VIDEO_SELECT_HIDE, VIDEO_SELECT_SET_IMAGE } from './actionTypes';

const initialState = {
  shown: false,
  currentImage: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VIDEO_SELECT_SHOW: {
      return {
        ...state,
        shown: true
      };
    }
    case VIDEO_SELECT_HIDE: {
      return {
        ...state,
        shown: false
      };
    }
    case VIDEO_SELECT_SET_IMAGE: {
      return {
        ...state,
        currentImage: action.payload
      };
    }
    default:
      return state;
  }
}
