import { createContext, useState } from 'react';
import { ModalWindow } from '../app/components/modal/Modal';

export const ModalContext = createContext({
  modal: [],
  setModal: () => {}
});

const initialModalState = {
  open: false,
  header: '',
  body: null,
  footer: null
};

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(initialModalState);

  const context = {
    modal,
    setModal
  };

  return (
    <ModalContext.Provider value={context}>
      {children}
      <ModalWindow />
    </ModalContext.Provider>
  );
};
