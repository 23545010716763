import { useDispatch, useSelector } from 'react-redux';
import { SET_WIDGET_SETTINGS } from '../../store/app/widget-settings/actionTypes';

export const useWidgetSettings = (overrideSettings = {}) => {
  const settings = useSelector(state => state.app.widgetSettings);
  const dispatch = useDispatch();

  const setActiveWidgetSettings = widget => {
    const widgetSettings = JSON.parse(JSON.stringify(widget.settings || {}));
    dispatch({ type: SET_WIDGET_SETTINGS, payload: { ...widgetSettings, ...overrideSettings } });
  };

  return {
    settings,
    setActiveWidgetSettings
  };
};
