import { IMAGE_EDITOR_SHOW, IMAGE_EDITOR_HIDE, IMAGE_EDITOR_SET_IMAGE } from './actionTypes';

const initialState = {
  shown: false,
  currentImage: null
};

export default function(state = initialState, action) {
  switch (action.type) {
  case IMAGE_EDITOR_SHOW: {
    return {
      ...state,
      shown: true
    };
  }
  case IMAGE_EDITOR_HIDE: {
    return {
      ...state,
      shown: false
    };
  }
  case IMAGE_EDITOR_SET_IMAGE: {
    return {
      ...state,
      currentImage: action.payload
    };
  }
  default:
    return state;
  }
}
