import { LIST_EDITOR_SHOW, LIST_EDITOR_HIDE } from "./actionTypes";

const initialState = {
  shown: false,
  componentId: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_EDITOR_SHOW: {
      return {
        ...state,
        shown: true,
        componentId: action.payload
      };
    }
    case LIST_EDITOR_HIDE: {
      return {
        ...state,
        shown: false,
        componentId: undefined
      };
    }
    default:
      return state;
  }
}
