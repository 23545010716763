import React from 'react';
import TopBar from '../builder/Interface/TopBar/TopBar';
import styles from './Plan.module.css';
import { Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePurchases from '../../hooks/usePurchases';
import { format } from 'date-fns';
import { usePageView } from '../../hooks/useAnalytics';

const Plans = ({ onClose }) => {
  const { t } = useTranslation();
  const { lastPurchase } = usePurchases();
  usePageView('Your Plan Info');

  if (!(lastPurchase && Object.keys(lastPurchase).length)) {
    onClose();
    return null;
  }

  const infoList = [
    { key: t('Name'), value: lastPurchase.plan.title },
    { key: t('Plan Price'), value: String(lastPurchase.plan.price) },
    { key: t('Circle'), value: 'annually' },
    { key: t('Expiry date'), value: format(new Date(lastPurchase.expired), 'MM/dd/yyyy') },
    {
      key: t('Features'),
      value: (
        <React.Fragment>
          <p className={'fs-5 text-start mb-0'}>{t('5 Pages')}</p>
          <p className={'fs-5 text-start mb-0'}>{t('Analytics')}</p>
          <p className={'fs-5 text-start mb-0'}>{t('Premium Styles')}</p>
        </React.Fragment>
      )
    }
  ];

  return (
    <div className={'bootstrap-app-styles builder-styles ' + styles.plans}>
      <TopBar onClick={onClose}>{t('Your Plan Info')}</TopBar>
      <Container className={'text-center pb-2'}>
        <Container className={'text-center pb-2'}>
          <Table>
            <tbody>
              {infoList.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <p className={'fs-5 fw-bold text-start mb-0'}>{item.key}</p>
                    </td>
                    <td>
                      {typeof item.value === 'string' ? (
                        <p className={'fs-5 text-start mb-0'}>{item.value}</p>
                      ) : (
                        item.value
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Container>
    </div>
  );
};

export default Plans;
