import { Image } from 'react-bootstrap';
import Logo from '../../assets/8bio-wt.png';

export const Placeholder = () => {
  return (
    <div className={`d-flex justify-content-center align-items-center h-100`}>
      <Image className="h-50" src={Logo} />
    </div>
  );
};
