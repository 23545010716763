export const decodeHTML = html => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const createInstagramAuthURL = options => {
  const url = 'https://www.instagram.com/oauth/authorize/';
  const params = Object.entries(options).reduce((acc, [key, value]) => {
    const foreSign = acc ? '&' : '?';
    acc += `${foreSign}${key}=${value}`;
    return acc;
  }, '');
  return url + params;
};
