import React, { useEffect, useState } from 'react';
import InstagramFeed from './InstagramFeed';
import useProjects from '../../hooks/useProjects';

const Layouts = () => {
  const { getProjects } = useProjects();
  const [widget, setWidget] = useState(null);

  useEffect(() => {
    getProjects().then(response => {
      setWidget(response[0].app.name);
    });
  }, [widget]);

  return <>{widget === 'instagram-feed' ? <InstagramFeed /> : null}</>;
};

export default Layouts;
