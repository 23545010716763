import { INSTAGRAM_FEED_TITLE_UPDATE, INSTAGRAM_FEED_LAYOUT_UPDATE } from './actionTypes';

export default function instagramFeedReducer(state = {}, action) {
  switch (action.type) {
    case INSTAGRAM_FEED_TITLE_UPDATE:
      return { ...state, title: action.payload };
    case INSTAGRAM_FEED_LAYOUT_UPDATE:
      return {
        ...state,
        params: { ...state.params, ...action.payload }
      };
    default:
      return state;
  }
}
