import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CopyButton.module.css';
import { Button, Row, Col } from 'react-bootstrap';
import { copyToClipboard } from './helpers';
import { useAnalytics } from '../../hooks/useAnalytics';

const CopyButton = ({ textToCopy }) => {
  const [copy, setCopy] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const { t } = useTranslation();
  const { sendEvent } = useAnalytics();

  useEffect(() => {
    let timer;
    if (copy) {
      copyToClipboard(textToCopy).then(() => {
        setTextCopied(true);
        timer = setTimeout(() => {
          setTextCopied(false);
          setCopy(false);
        }, 2000);
        sendEvent('share', {
          method: 'Copy'
        });
      });
    }
    return () => {
      clearTimeout(timer);
    };
  }, [copy]);

  return (
    <Button
      className={styles.CopyButton}
      onClick={() => setCopy(true)}
      variant={textCopied ? 'success' : 'light'}>
      {!textCopied ? (
        <Row className="d-flex text-start">
          <Col className={styles.copy_col}>
            <p className={`mb-0 ${styles.CopyButton_link}`}>{textToCopy}</p>
          </Col>
          <Col xs={'auto'}>
            <p className="mb-0">{t('Copy')}</p>
          </Col>
        </Row>
      ) : (
        <p className={'mb-0'}>{t('Copied!')}</p>
      )}
    </Button>
  );
};

export default CopyButton;
