import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GTMProvider, useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useLocation } from 'react-router-dom';

export const useAnalytics = () => {
  const account = useSelector(state => state.account);
  const [user_id, setUserId] = useState(account && account.id);
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    if (account) {
      setUserId(account.id);
    }
  }, [account]);

  function sendEvent(event, options) {
    return sendDataToGTM({ event, ...options });
  }

  function pageView(page_path, page_location, page_title) {
    const params = { event: 'virtual_pageview', page_path, page_location, page_title };
    if (user_id) params.user_id = user_id;
    return sendDataToGTM(params);
  }

  return {
    sendEvent,
    pageView
  };
};

export const usePageView = (page_title = 'Builder') => {
  const page_path = useLocation().pathname;
  const page_location = window.location.href;
  const { pageView } = useAnalytics();

  useEffect(() => {
    pageView(page_path, page_location, page_title);
  }, []);
};

export const AnalyticsProvider = ({ children }) => {
  const config = { id: process.env.REACT_APP_GTM_ID };
  return <GTMProvider state={config}>{children}</GTMProvider>;
};
