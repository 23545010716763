import instagramFeedSettingsReducers from './instagram-feed/reducers';
import ShopSettingsReducers from './shop/reducers';
import {
  INSTAGRAM_FEED_TITLE_UPDATE,
  INSTAGRAM_FEED_LAYOUT_UPDATE,
  SHOP_TITLE_UPDATE,
  SHOP_LAYOUT_UPDATE,
  SET_WIDGET_SETTINGS
} from './actionTypes';

export default function widgetReducers(state = {}, action) {
  switch (action.type) {
    case SET_WIDGET_SETTINGS:
      return { ...action.payload };
    case INSTAGRAM_FEED_TITLE_UPDATE:
    case INSTAGRAM_FEED_LAYOUT_UPDATE:
      return {
        ...state,
        ...instagramFeedSettingsReducers(state, action)
      };
    case SHOP_TITLE_UPDATE:
    case SHOP_LAYOUT_UPDATE:
      return {
        ...state,
        ...ShopSettingsReducers(state, action)
      };
    default:
      return state;
  }
}
