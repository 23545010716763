import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { House, Share, Grid, Gear, Pencil } from 'react-bootstrap-icons';
import { Nav, Navbar, Container, Row, Col } from 'react-bootstrap';
import styles from './NavigationBar.module.css';

const NavigationBar = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [forceActiveKey, setForceActiveKey] = useState('');

  const handleMenuClick = item => {
    if (!window.location.hash.includes(item.path)) navigate(item.path);
    setForceActiveKey(item.name);
  };

  const menuOptions = [
    {
      name: 'Home',
      icon: House,
      path: `/home`
    },
    {
      name: 'Layout',
      icon: Grid,
      path: `/styles`
    },
    {
      name: 'Edit',
      icon: Pencil,
      path: `/builder`
    },
    {
      name: 'Settings',
      icon: Gear,
      path: `/settings`
    }
    // {
    //   name: 'Share',
    //   icon: Share,
    //   path: `/share`
    // }
  ];

  useEffect(() => {
    menuOptions.forEach(item => {
      if (window.location.hash.includes(item.path)) setForceActiveKey(item.name);
    });
  }, [location]);

  return (
    <Navbar variant="light" className={styles.NavigationBar + ' justify-content-center'}>
      <Nav className={'w-100'} defaultActiveKey={'Edit'}>
        <Container className={'px-lg-3'}>
          <Row className="flex-lg-column">
            {menuOptions.map((item, idx) => {
              const activeProp = forceActiveKey ? { active: item.name === forceActiveKey } : {};
              const Icon = item.icon;
              return (
                <Nav.Item as={Col} key={idx} className={'p-0 text-center'}>
                  <Nav.Link
                    eventKey={item.name}
                    onClick={() => handleMenuClick(item)}
                    {...activeProp}>
                    <div className={styles.menu_item}>
                      {Icon && <Icon className={styles.menu_item_icon} />}
                      {<div className={styles.menu_item_name}>{t(item.name)}</div>}
                    </div>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Row>
        </Container>
      </Nav>
    </Navbar>
  );
};

export default NavigationBar;
