import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useNotifications from '../../hooks/useNotifications';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import styles from './ContactUs.module.css';
import Loader from '../builder/Interface/Loader/Loader';
import TopBar from '../builder/Interface/TopBar/TopBar';
import { Container, Form, Button } from 'react-bootstrap';
import { usePageView } from '../../hooks/useAnalytics';

const ContactUs = props => {
  const { t } = useTranslation();
  const { getProfile } = useAuth();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotifications();
  const navigate = useNavigate();
  usePageView('Contact Us');

  useEffect(() => {
    getProfile().then(account => {
      setEmail(account.email);
    });
  }, []);

  const sendForm = ev => {
    ev.preventDefault();
    if (!message || message.length < 100) {
      notification.warning(t('Your message is too short. Please add more details'));
      return false;
    }
    setIsLoading(true);

    let formData = new FormData();
    formData.append(
      'email',
      '2aOuQJRuMD9a34COkc48JjStNMZVM1kQ9wNpAbp1VyoSh087u4hveATM/YoQ2D9AzUyBoVFFGSNFmwSx/fi/GW0LiJycGb1YgDTiFjJ1pl+z7mC+5q4M6qM1FbAVtzLo'
    );
    formData.append('form[data][0][0]', 'Message send from');
    formData.append('form[data][0][1]', '$$$ 8BIO 8BIO 8BIO $$$');
    formData.append('form[data][1][0]', 'Email');
    formData.append('form[data][1][1]', email);
    formData.append('form[data][2][0]', 'Message');
    formData.append(
      'form[data][2][1]',
      message +
        `\n\n-----------------------------------------------------------------------\n
      Platform: ${window.navigator.platform}, ${window.navigator.userAgent}
      \n-----------------------------------------------------------------------\n`
    );

    // Temporary solution
    fetch(
      `https://formoid.net/api/amp-form/push?__amp_source_origin=${window.location.href.slice(
        0,
        -1
      )}`,
      {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
      }
    )
      .then(res => {
        notification.success(
          `${t('Your message has been successfully sent!')}
         ${t('We will reply to your email $userEmail$ shortly.').replace(
           '$userEmail$',
           `${email}`
         )}`
        );
      })
      .catch(err => {
        console.log(err);
        notification.warning(t('Something went wrong'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={`bootstrap-app-styles builder-styles ${styles.root}`}>
      <TopBar onClick={() => navigate(-1)}>{t('Send us a message')}</TopBar>
      {isLoading ? (
        <>
          <p className={'text-center'}>{t('Message sending...')}</p>
          <Loader />
        </>
      ) : (
        <>
          <Container className="mt-2">
            <Form className="d-flex flex-column">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t('From')}</Form.Label>
                <Form.Control type="email" value={email} disabled placeholder={t('Enter email')} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>{t('Message')}:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={message}
                  onChange={event => {
                    setMessage(event.target.value);
                  }}
                  placeholder="Please explain in details what happened and what steps we can take to reproduce the problem."
                  style={{ height: '100px' }}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className={'align-self-stretch'}
                onClick={sendForm}>
                {t('Send')}
              </Button>
            </Form>
          </Container>
        </>
      )}
    </div>
  );
};

export default ContactUs;
